import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useGetAllDocuments, removeDocument } from "../../utils/Kurvanstore";

import styled from "styled-components";

const TableWrapper = styled.table`
  tr.clickable:hover td {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

export default function DataTable({
  tablename,
  fields,
  beginExpanded,
  onRowClick,
  refetchInterval,
  ...props
}) {
  const { data, loading, refetch } = useGetAllDocuments(tablename);
  let list = (data || []).map((i) => ({ ...i, data: JSON.parse(i.data) }))
  const [tableHidden, setTableHidden] = useState(!beginExpanded);
  const navigate = useNavigate();
  if(props.filter)
    list = props.filter(list)

  useEffect(() => {
    if (!refetchInterval || refetchInterval < 1) return;

    const intervalDuration = parseInt(refetchInterval) * 1000;

    const interval = setInterval(() => {
      refetch();
    }, intervalDuration);
    return () => clearInterval(interval);
  }, [tablename, refetchInterval]);

  const headers = (["", ...fields, ""] || ["No fields"]).map((f) => {
    return (
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {f}
      </th>
    );
  });
  const objectToString = (object) => {
    var str = "";

    for (var k in object) {
      if (object.hasOwnProperty(k)) {
        str += k + ": " + object[k] + " ";
      }
    }
    return str;
  };

  var rows = [];
  if (props.children) {
    rows = list.map((queueItem, idx) =>
      props.children({ item: queueItem, refetch, onRowClick, props })
    );
  } else {
    rows = list.map((queueitem, idx) => {
      const image = queueitem.data;
      return (
        <tr
          key={idx}
          className={onRowClick ? "clickable" : ""}
          onClick={() => {
            if (onRowClick) {
              onRowClick(image);
            }
          }}
        >
          <td>
            <img
              className="h-8 ml-auto"
              src={`https://kurvan-io.imgix.net/import_folder/${image.id}?h=32`}
            />
          </td>
          {fields.map((field) => {
            let show = "unknown type";

            if (field === "error") {
              show = queueitem.error ? (
                <span style={{ color: "red" }}>{queueitem.error}</span>
              ) : (
                ""
              );
            } else {
              const fieldData = image[field];
              const fieldType = typeof fieldData;

              if (fieldType === "string") {
                show = fieldData;
              } else if (fieldType === "object") {
                show = objectToString(fieldData);
              }
            }

            return <td className="px-6 py-4 whitespace-nowrap">{show}</td>;
          })}
          <td>
            {props.reprocess && (
              <i
                className="fa-solid fa-rotate text-gray-500 hover:text-gray-800 p-4"
                title="Senda í vinnsluröð"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  props.reprocess(image, refetch);
                }}
              />
            )}
          </td>
          <td>
            <i
              className="fa-solid fa-trash text-red-500 hover:text-red-800 p-4"
              title="Eyða"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                removeDocument(tablename, image.id);
                refetch();
                NotificationManager.warning(
                  `Removed item ${image.id} from ${tablename}`
                );
              }}
            />
          </td>
        </tr>
      );
    });
  }

  return (
    <TableWrapper className="min-w-full divide-y divide-gray-200 mt-2">
      <thead
        className="bg-gray-50"
        onClick={() => setTableHidden(!tableHidden)}
      >
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            colSpan="100%"
          >
            {props.title || tablename.replaceAll("_", " ")}&nbsp; ({rows.length}
            )
          </th>
        </tr>
        <tr>{headers}</tr>
      </thead>
      <tbody
        className={`bg-white divide-y divide-gray-200 ${
          tableHidden ? "hidden" : ""
        }`}
      >
        {rows.length !== 0 ? (
          rows
        ) : (
          <tr>
            <td
              className="px-6 py-4 whitespace-nowrap text-center"
              colSpan="100%"
            >
              no rows
            </td>
          </tr>
        )}
      </tbody>
    </TableWrapper>
  );
}

DataTable.defaultProps = {
  fields: [],
  rowsClickable: false,
  expandable: false,
  beginExpanded: false,
};
