import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { useParams } from "react-router-dom";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import { useGetDocumentById, useGetAllDocuments } from "../utils/Kurvanstore";
import {
  getZoomLevel,
  getMapCenter,
  defaultCoords,
  MapMarker,
} from "../utils/GoogleMaps";
import { uploadImage } from "../utils/Utils";

import * as moment from "moment";
import "moment/locale/is";
moment.locale("is");

export default function Measurement() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [feedback, setFeedback] = useState("");

  const [formData, setFormData] = useState({
    id: undefined,
    date: moment().format("DD/MM/YYYY"),
  });

  const { id } = useParams();
  const { data, loading, setData } = useGetDocumentById(
    "area_measurements",
    id
  );
  //const { data: allStores,  loading: areasLoading } = useGetAllDocuments('store_areas');
  const { data: allAreas, loading: areasLoading } =
    useGetAllDocuments("store_areas");

  //const stores = (allAreas||[]).map(a => a.store).unique("id");

  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  //const [storeId, setStoreId] = useState();

  useEffect(() => {
    if (data !== null && data.id !== id) {
      setEdited(true);
      setIsNew(true);
    } else {
      setIsNew(false);
      setEdited(false);
    }
    if (data) {
      //setStoreId(data.store.id);
      setFormData({ ...data, date: moment(data.date).format("DD/MM/YYYY") });
    } else {
      setFormData({ id, date: moment().format("DD/MM/YYYY") });
    }
    setSubmitting(false);
  }, [data]);

  //useEffect(()=> {
  //  //console.log(storeId, formData);
  //  if(allAreas) {
  //    const areas = allAreas.filter(a => a.store.id == storeId)
  //    const currentIds = areas.map(a => a.id);
  //    if(!currentIds.includes(formData.storeAreaId) && currentIds.length > 0) {
  //      console.log("updating", currentIds[0]);
  //      updateFormDataState("storeAreaId", currentIds[0]);
  //    }
  //  }
  //}, [storeId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const date = moment(formData.date, "DD/MM/YYYY");
    if (!date.isValid()) {
      setFeedback(
        "Dagsetning er ekki gild, settu með sniðinu DD/MM/YYYY! t.d. 25/1/2020"
      );
      return;
    }

    if (image) {
      const area = allAreas.find((a) => a.id == formData.storeAreaId);
      const storeId = area.store.id;
      const imageId = await uploadImage(
        "/admin/shelf_import_image",
        image,
        storeId
      );
      URL.revokeObjectURL(imageUrl);

      //TODO: delete old image?
      await setData({ ...formData, date: date.toISOString(), imageId });
    } else {
      await setData({ ...formData, date: date.toISOString() });
    }
    setSubmitting(true);
    //location.reload();
  };

  const updateformData = (fieldName) => (event) => {
    updateFormDataState(fieldName, event.target.value);
  };

  const innerMapFieldUpdate = (outerKey, innerKey, field, value) => {
    innerMapUpdate(outerKey, innerKey, {
      ...formData[outerKey][innerKey],
      [field]: value,
    });
  };
  const innerMapUpdate = (outerKey, innerKey, value) => {
    updateFormDataState(outerKey, {
      ...formData[outerKey],
      [innerKey]: value,
    });
  };

  const updateFormDataState = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
    setEdited(true);
    setSubmitting(false);
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Measurement &nbsp;
                      <span className="text-sm text-red-600">
                        {edited ? "(Unsaved Changes)" : ""}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This the system information behind each Measurement to be
                      careful what you edit
                    </p>
                    <p className="mt-1 text-sm text-red-600">{feedback}</p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" onSubmit={onSubmit}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Dagsetning
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="date"
                              id="date"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.date}
                              onChange={(e) =>
                                updateFormDataState("date", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="area"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Svæði
                          </label>
                          <div className="mt-1">
                            {/*
                            <select
                              id="area"
                              value={storeId}
                              onChange={(e)=>{
                                const selectedId = e.target.value;
                                setStoreId(selectedId);
                              }}>
                              {stores
                                .sort((a,b)=> a.name.localeCompare(b.name))
                                .map(s => <option value={s.id}>{s.name}</option>)}
                            </select>
                            */}
                            <select
                              id="area"
                              value={formData.storeAreaId}
                              onChange={(e) => {
                                const selectedId = e.target.value;
                                updateFormDataState("storeAreaId", selectedId);
                              }}
                            >
                              {(allAreas || [])
                                .map((s) => ({
                                  ...s,
                                  option: `${s.store.name} - ${s.title}`,
                                }))
                                //.filter(a => a.store.id == storeId)
                                .sort((a, b) =>
                                  a.option.localeCompare(b.option)
                                )
                                .map((a) => (
                                  <option value={a.id}>{a.option}</option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Logo (Virkar en þarft að ýta á vista takkann)
                          </label>
                          <div className="mt-1 flex items-center">
                            <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                              {image ? (
                                <img
                                  className="h-full w-full text-gray-300"
                                  src={imageUrl}
                                />
                              ) : (
                                <img
                                  className="h-full w-full text-gray-300"
                                  src={
                                    "https://kurvan-io.imgix.net/shelf_import/" +
                                    formData.imageId
                                  }
                                />
                              )}
                            </span>

                            <label className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                onChange={async (e) => {
                                  if (image) {
                                    URL.revokeObjectURL(imageUrl);
                                  }
                                  const file = e.target.files[0];
                                  setImage(file);
                                  setImageUrl(URL.createObjectURL(file));
                                  setEdited(true);
                                  setSubmitting(false);
                                }}
                              />
                              Change
                            </label>
                            {image && (
                              <label className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <button
                                  onClick={async (e) => {
                                    if (image) {
                                      URL.revokeObjectURL(formData.logoUrl);
                                    }
                                    setImage(undefined);
                                    setImageUrl(undefined);
                                  }}
                                />
                                remove
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Link
                          to="/measurements"
                          className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {submitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
