import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import {
  useGetAllDocuments,
  useGetDocumentById,
  useGetDocument,
} from "../utils/Kurvanstore";
import { useParams } from "react-router-dom";
import { uploadFile } from "../utils/Utils";

export default function Product() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({ title: "" });
  const [edited, setEdited] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showCount, setShowCount] = useState(10);
  const [image, setImage] = useState();

  const { id } = useParams();
  const { data, loading, setData } = useGetDocumentById("products", id);
  const { data: allSuppliers } = useGetAllDocuments("suppliers");
  const { data: allTrademarks } = useGetAllDocuments("trademarks");
  const { data: allSubCategories } = useGetAllDocuments("sub_categories");
  const { data: prices } = useGetDocument(
    `/admin/price_survey_points/by_product/${id}`
  );

  useEffect(() => {
    if (data !== null && data.id !== id) {
      setEdited(true);
      setIsNew(true);
    } else {
      setIsNew(false);
      setEdited(false);
    }
    setFormData(data || { id });
    setSubmitted(false);
  }, [data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (image) {
      const bucketPath = await uploadFile("/admin/product_logo", image);
      const logoUrl = bucketPath
        ? "https://kurvan-io.imgix.net" + bucketPath
        : data.logoUrl;
      URL.revokeObjectURL(formData.logoUrl);

      setData({ ...formData, logoUrl });
    } else {
      setData(formData);
    }
    setSubmitted(true);
  };

  const updateformData = (fieldName) => (event) => {
    updateFormDataState(fieldName, event.target.value);
  };

  const innerMapFieldUpdate = (outerKey, innerKey, field, value) => {
    innerMapUpdate(outerKey, innerKey, {
      ...formData[outerKey][innerKey],
      [field]: value,
    });
  };
  const innerMapUpdate = (outerKey, innerKey, value) => {
    updateFormDataState(outerKey, {
      ...formData[outerKey],
      [innerKey]: value,
    });
  };

  const updateFormDataState = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
    setEdited(true);
    setSubmitted(false);
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Product &nbsp;
                      <span className="text-sm text-red-600">
                        {edited ? "(Unsaved Changes)" : ""}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This the system information behind each Product to be
                      careful what you edit
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" onSubmit={onSubmit}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div>
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="title"
                              id="title"
                              minLength="2"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.title}
                              onChange={updateformData("title")}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="unit"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Eining / Unit
                          </label>
                          <div className="mt-1">
                            <select
                              name="unit"
                              value={formData.unit}
                              onChange={updateformData("unit")}
                            >
                              <option value={null}></option>
                              <option>ltr</option>
                              <option>kg</option>
                              <option>stk</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="logoUrl"
                            className="block text-sm font-medium text-gray-700"
                          >
                            quantity
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="quantity"
                              id="quantity"
                              title="needs to be a float"
                              pattern="[-+]?[0-9]*[.,]?[0-9]+"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.quantity}
                              onChange={updateformData("quantity")}
                            />
                          </div>
                        </div>

                        {false && !image && (
                          <div>
                            <label
                              htmlFor="logoUrl"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Logo image path
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="logoUrl"
                                id="logoUrl"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                value={formData.logoUrl}
                                onChange={updateformData("logoUrl") || ""}
                              />
                            </div>
                          </div>
                        )}

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Logo
                          </label>
                          <div className="mt-1 flex items-center">
                            <span
                              className="inline-block max-h-20 w-12 overflow-hidden bg-gray-100"
                              style={{ background: "magenta", padding: "2px" }}
                            >
                              {formData.logoUrl ? (
                                <img
                                  className="h-full w-full text-gray-300"
                                  src={formData.logoUrl}
                                />
                              ) : (
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              )}
                            </span>

                            <label className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                onChange={async (e) => {
                                  if (image) {
                                    URL.revokeObjectURL(formData.logoUrl);
                                  }
                                  const file = e.target.files[0];
                                  setImage(file);
                                  updateFormDataState(
                                    "logoUrl",
                                    URL.createObjectURL(file)
                                  );
                                }}
                              />
                              Change
                            </label>
                            {image && (
                              <label className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <button
                                  onClick={async (e) => {
                                    if (image) {
                                      URL.revokeObjectURL(formData.logoUrl);
                                    }
                                    setImage(undefined);
                                    updateFormDataState(
                                      "logoUrl",
                                      data.logoUrl
                                    );
                                  }}
                                />
                                remove
                              </label>
                            )}
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="trademarkId"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Vörumerki / Trademark
                          </label>
                          <div className="mt-1">
                            <select
                              name="trademarkId"
                              value={formData.trademarkId}
                              onChange={updateformData("trademarkId")}
                            >
                              <option>Ekkert valið</option>
                              {(allTrademarks || [])
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((s) => (
                                  <option key={s.id} value={s.id}>
                                    {s.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="supplierId"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Birgi / Supplier
                          </label>
                          <div className="mt-1">
                            <select
                              name="supplierId"
                              value={formData.supplierId}
                              onChange={updateformData("supplierId")}
                            >
                              <option>Ekkert valið</option>
                              {(allSuppliers || [])
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((s) => (
                                  <option key={s.id} value={s.id}>
                                    {s.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Undirflokkar / Subcategories
                          </label>

                          <div className="mt-1 space-x-2">
                            {allSubCategories
                              ?.filter((sc) =>
                                formData.subCategoryIds?.includes(sc.id)
                              )
                              .map((sc) => (
                                <span
                                  className="py-1 px-2.5 text-xs bg-blue-600 text-white rounded-full cursor-pointer"
                                  onClick={() =>
                                    updateFormDataState(
                                      "subCategoryIds",
                                      formData.subCategoryIds.filter(
                                        (id) => id != sc.id
                                      )
                                    )
                                  }
                                >
                                  {sc.category?.title + " - " + sc.title}{" "}
                                  <i className="fa-solid fa-times"></i>
                                </span>
                              ))}
                          </div>
                          {/*
                           */}

                          <div className="mt-1">
                            <select
                              value=""
                              onChange={(e) => {
                                const selectedId = e.target.value;
                                console.log(selectedId);
                                //updateFormDataState("subCategoryId", selectedId);
                                updateFormDataState("subCategoryIds", [
                                  ...(formData.subCategoryIds || []),
                                  selectedId,
                                ]);
                              }}
                            >
                              <option>
                                Veldu til þess að bæta við undirflokk
                              </option>
                              {(allSubCategories || [])
                                //.filter(sc => !formData.subCategoryIds?.includes(sc.id))
                                .map((s) => ({
                                  ...s,
                                  option: s.category?.title + " - " + s.title,
                                }))
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((s) => (
                                  <option value={s.id}>
                                    {s.category ? s.option : s.title}
                                  </option>
                                ))}
                            </select>
                          </div>

                          {/*
                          <div className="mt-1">
                            <select
                              multiple={true}
                              value={formData.subCategoryIds||[]}
                              onChange={(e)=>{
                                const selectedId = e.target.value;
                                if((formData.subCategoryIds || []).includes(selectedId)){
                                  updateFormDataState("subCategoryIds", formData.subCategoryIds.filter(sc => sc != selectedId));
                                }else{
                                  updateFormDataState("subCategoryIds", [...(formData.subCategoryIds||[]), selectedId]);
                                }
                              }}>
                              {(allSubCategories||[])
                                //.filter(sc => !formData.subCategoryIds?.includes(sc.id))
                                .map(s => ({...s, option: s.category?.title+" - "+s.title}))
                                .sort((a,b)=> a.title.localeCompare(b.title))
                                .map((s)=><option value={s.id}>{s.category?s.option:s.title}</option>)}
                            </select>
                          </div>
                          */}
                        </div>
                      </div>

                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Link
                          to="/Products"
                          className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {submitted ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="bg-white rounded-sm p-2 m-2">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            <span className="text-lg">Dags.</span>
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <span className="text-lg">Búð</span>
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <span className="text-lg">Verð</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {(prices || [])
                          .sort((a, b) => b.date.localeCompare(a.date))
                          .slice(0, showCount)
                          .map((p) => {
                            return (
                              <tr key={p.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="ml-4 text-sm font-medium text-gray-900">
                                    {new Date(p.date).toLocaleDateString(
                                      "en-GB"
                                    )}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="ml-4 text-sm font-medium text-gray-900">
                                    {p.store.name}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="ml-4 text-sm font-medium text-gray-900">
                                    {p.price}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        {(prices || []).length > showCount && (
                          <tr>
                            <td
                              colSpan="3"
                              className="p-2"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                onClick={() => setShowCount(showCount + 10)}
                              >
                                more
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
