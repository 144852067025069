import React from "react";

function HfgLogo(props) {
  const color = "#ffffff";
  return (
    <svg {...props}>
      <path
        d="m 31.8836 5 l 0 100.2515 l 14.764 0 l 0 -43.8 l 35.7 0 l 0 -12.1897 l -35.7 0 V 5 z"
        style={{
          fill: color,
        }}
      />
      <path
        d="m 139.782 105.7003 l 0.167 -12.1608 c -11.292 -0.4576 -20.2558 -5.5803 -26.1164 -12.3584 c -7.0776 -8.1857 -9.8742 -18.939 -9.9766 -28.8038 c -0.1197 -11.527 5.6828 -25.1109 16.845 -31.8425 c 14.764 -8.9038 36.467 -7.1378 46.2088 7.9246 l 10.2231 -10.3567 c -21.6942 -23.9415 -66.5828 -19.5795 -81.6476 10.1178 c -13.841 27.285 -4.9009 75.0016 44.2967 77.4797 z"
        style={{
          fill: color,
        }}
      />
      <path
        d="m 144.9937 48 l 35.6805 0 l 0 12.428 l -35.8141 0 z"
        style={{
          fill: color,
        }}
      />
    </svg>
  );
}

export default HfgLogo;
