import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Banner from "../../partials/Banner";
//import Datepicker from '../../partials/actions/Datepicker';
import Flatpickr from "react-flatpickr";
import Messages from "./Messages";
import DataTable from "./DataTable";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import moment from "moment";
import {
  useGetAllDocuments,
  useGetDocumentById,
} from "../../utils/Kurvanstore";
import globals from "../../globals";

const isString = (str) => {
  return Object.prototype.toString.call(str) === "[object String]";
}

const SearchCard = styled.div`
  padding: 4px;
  height: calc(100vh - 110px);
  background: white;
  border-radius: 3px;
  min-width: 260px;
`;
//bg-white rounded-sm

const InputStageWrapper = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.3);
  height: calc(100% - 26px);
  position: relative;

  input {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    border-top: 2px solid rgba(0, 0, 0, 0.3);
    outline: none;
    padding: 5px;

    &:active {
    }
    &:focus {
    }
  }
`;

const FadeToNone = styled.div`
  position: relative;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  user-select: none;
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 13%);
`;

const ProcessListCheck = styled.label`
  input {
    display: none;
  }
  label {
    display: block;
    border: 2px solid rgba(0, 0, 0, 0.2);
    margin: 5px 4px;
    padding: 4px 5px;
    cursor: pointer;
    &.Selected {
      border: 2px solid rgb(4, 120, 87);
    }
    i.fa-square-check {
      color: rgb(4, 120, 87);
    }
  }
`;

const TagDisplay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 6px;
  float: left;
  font-weight: bold;
  font-size: 0.8em;

  margin-bottom: 2px;
  & + & {
    margin-left: 2px;
  }
`;

async function SubmitFinal(data) {


}
async function SubmitFinalToFunction(data) {
  const url = globals.functions_url + "/SubmitToFinalizeQueue";
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "api-key": "a1c72283-84b0-4a52-8034-f43a6e30c7be",
    },
  });
}
function Image(props) {
  return <ReactPanZoom image={props.src} />;
}

function filterSearch(search, objProp) {
  return (item) => {
    if (item.name === "") return false;
    return search === "" || item[objProp].search(new RegExp(search, "i")) >= 0;
  };
}

function ListNavigation(
  selection,
  setSelection,
  filteredList,
  onFound,
  onCreate
) {
  return (e) => {
    if (e.key == "Escape") {
      setSelection(-1);
    }
    if (e.key == "ArrowUp") {
      const filteredLength = filteredList.length;
      if (filteredLength !== 0)
        setSelection((selection - 1 + filteredLength) % filteredLength);
      else setSelection(-1);
    }
    if (e.key == "ArrowDown") {
      const filteredLength = filteredList.length;
      if (filteredLength !== 0) setSelection((selection + 1) % filteredLength);
      else setSelection(-1);
    }
    if (e.key == "Enter") {
      if (selection > -1 && filteredList.length > 0) {
        const found = filteredList.at(selection);
        if (found) {
          onFound(found);
        } else {
          console.log("not Found");
        }
      } else if (filteredList.length == 1) {
        const found = filteredList.at(0);
        onFound(found);
      } else if (filteredList.length > 0) {
        setSelection(0);
      } else {
        onCreate();
      }
    }
  };
}

function ProcessingItem({ id, redirectToNextItem }) {
  const { data, loading, setData } = useGetDocumentById(
    "human_processing_queue",
    id
  );
  const item = data === null ? null : JSON.parse(data.data);
  const { data: allTags } = useGetAllDocuments("tags");
  const { data: allProducts } = useGetAllDocuments("products");

  const [datetimeTaken, setDatetimeTaken] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [store, setStore] = useState(undefined);
  const [products, setProducts] = useState([]);
  const [needsAttention, setNeedsAttention] = useState(false);
  const [comment, setComment] = useState("");
  const [projects, setProjects] = useState([]);
  const [working, setWorking] = useState(false);
  const [feedback, setFeedback] = useState(undefined);

  const [tagSearch, setTagSearch] = useState("");
  const [tagSelection, setTagSelection] = useState(-1);
  const [productSearch, setProductSearch] = useState("");
  const [productSelection, setProductSelection] = useState(-1);
  const [projectSearch, setProjectSearch] = useState("");
  const [projectSelection, setProjectSelection] = useState(-1);

  useEffect(() => {
    setFeedback(undefined);
  }, [store, projects]);

  useEffect(() => {
    if (!item) return;
    if (
      item.store_guesses &&
      item.store_guesses.length > 0 &&
      item.store_guesses.length < 6
    )
      setStore(item.store_guesses[0]);
  }, [data]);

  if (loading) {
    return <span>Loading</span>;
  }

  if (!item) {
    return <span>item not found</span>;
  }

  //TODO: there is a bug where selection can be bigger then filterList
  //      if selection > filterList.length do setSelection(filterList.length)
  const projectsFilteredList = (item.project_guesses || [])
    .sort((a, b) => a.title.localeCompare(b.title))
    .filter(filterSearch(projectSearch, "title"));

  const productsFilteredList = (allProducts || [])
    .sort((a, b) => a.title.localeCompare(b.title))
    .filter(filterSearch(productSearch, "title"));

  const tagsFilteredList = (allTags || [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(filterSearch(tagSearch, "name"));

  return (
    <div>
      <div className="flex flex-row">
        <div className="basis-1/4" style={{ width: 400 }}>
          <div
            style={{
              maxWidth: "400px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Image
              src={`https://kurvan-io.imgix.net/import_folder/${item.id}`}
            />
          </div>

          <div className="m-1 px-3 py-1 bg-white rounded-sm">
            <span className="text-sm font-bold">Imported by:</span>
            <div className="ml-1">{item.user?.name ?? "Unknown User"}</div>
            <div className="ml-1">{item.user?.email ?? "Unknown email"}</div>
            <div className="text-sm font-bold">Date imported:</div>
            <div className="ml-1">
              {moment(item.dateImported).format("HH:mm D/M/Y")} (
              {moment(item.dateImported).fromNow()})
            </div>
            <div className="text-sm font-bold">Date taken:</div>
            <div className="ml-1">
              {item.datetimeTaken ? (
                `${moment(item.datetimeTaken).format("HH:mm D/M/Y")}(${moment(
                  item.datetimeTaken
                ).fromNow()})`
              ) : (
                <Flatpickr
                  onChange={(e) => {
                    setDatetimeTaken(e[0]);
                  }}
                />
              )}
            </div>
            <div className="text-sm font-bold">Store:</div>
            <div className="ml-1">
              {item.store ? (
                item.store.name
              ) : item.store_guesses ? (
                <select
                  onChange={(e) => {
                    setStore(
                      item.store_guesses.find((s) => s.id === e.target.value)
                    );
                  }}
                  value={store?.id}
                >
                  <option key={""} value={null}>
                    Velja búð
                  </option>
                  {item.store_guesses.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}{" "}
                      {s.dist !== null
                        ? `(${(s.dist * 1000).toFixed(0)}m)`
                        : ""}
                    </option>
                  ))}
                </select>
              ) : (
                "store missing"
              )}
            </div>
            <div className="text-sm font-bold">Needs attention:</div>
            <div className="ml-1 overflow-hidden">
              <input
                id={"needsAttention"}
                type="checkbox"
                checked={needsAttention}
                onChange={(e) => {
                  setNeedsAttention(!needsAttention);
                }}
              />
              <label
                className={needsAttention ? "Selected" : ""}
                htmlFor={"needsAttention"}
              >
                &nbsp;
              </label>
            </div>
            <div className="text-sm font-bold">Comment:</div>
            <div className="ml-1 overflow-hidden">
              <textarea
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                value={comment}
              ></textarea>
            </div>
            <div className="text-sm font-bold">Projects:</div>
            <div className="ml-1 overflow-hidden">
              {(item.project_guesses || [])
                .filter((project) => projects.includes(project.id))
                .map((project) => (
                  <TagDisplay key={project.id}>
                    {project.title}
                    &nbsp;
                    <i
                      title="Remove"
                      className="fa-solid fa-square-xmark text-stone-300 cursor-pointer"
                      onClick={() => {
                        setProjects(projects.filter((id) => id !== project.id));
                      }}
                    />
                  </TagDisplay>
                ))}
              &nbsp;
            </div>
            <div className="text-sm font-bold">Products:</div>
            <div className="ml-1 overflow-hidden">
              {(allProducts || [])
                .filter((product) => products.includes(product.id))
                .map((product) => (
                  <TagDisplay key={product.id}>
                    {product.title}
                    &nbsp;
                    <i
                      title="Remove"
                      className="fa-solid fa-square-xmark text-stone-300 cursor-pointer"
                      onClick={() => {
                        setProducts(products.filter((id) => id !== product.id));
                      }}
                    />
                  </TagDisplay>
                ))}
              &nbsp;
            </div>
            <div className="text-sm font-bold">Tags:</div>
            <div className="ml-1 overflow-hidden">
              {(allTags || [])
                .filter((tag) => tags.includes(tag.id))
                .map((tag) => (
                  <TagDisplay key={tag.id}>
                    {tag.name}
                    &nbsp;
                    <i
                      title="Remove"
                      className="fa-solid fa-square-xmark text-stone-300 cursor-pointer"
                      onClick={() => {
                        setTags(tags.filter((id) => id !== tag.id));
                      }}
                    />
                  </TagDisplay>
                ))}
              &nbsp;
            </div>
            <div className="ml-1 overflow-hidden text-right">
              {feedback && <div className="text-red-400 m-1">* {feedback}</div>}
              <button
                tabIndex={2}
                type="button"
                className={
                  "m-1 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center" +
                  (working ? "cursor-wait" : "")
                }
                onClick={async () => {
                  if (!working) {
                    if (!item.store && !store) {
                      setFeedback("Need to add store!");
                      return;
                    }
                    if (!projects.length === 0) {
                      setFeedback("No project selected");
                      return;
                    }

                    if (!item.datetimeTaken && !datetimeTaken) {
                      setFeedback("Need to have a date taken");
                      return;
                    }
                    setWorking(true);

                    var submitItem = {
                      id: item.id,
                      filename: item.filename,
                      datetimeTaken:
                        item.datetimeTaken ?? datetimeTaken?.toISOString(),
                      dateImported: item.dateImported,
                      storeId: item.store?.id || store?.id,
                      projectIds: projects,
                      tagIds: tags,
                      productIds: products,
                      needsAttention,
                      comment,
                    };

                    const result = await fetch(`${globals.backend_url}/admin/submit_image`, {
                      method: "POST",
                      body: JSON.stringify(submitItem),
                      mode: "cors",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                        "api-key": "qC4YjXVCuSYLnWXk2e3Vsr8",

                      },
                    }).then((r) => r.json())
                      .catch((e)=> {
                        console.log("error", e);
                        return e;
                      });
                    setWorking(false);
                    if (redirectToNextItem) redirectToNextItem(result);
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="basis-1/4 px-2">
          <SearchCard className="">
            <span className="text-lg">Herferðir / Gæðaeftirlit</span>
            <InputStageWrapper>
              <FadeToNone className="overflow-y-scroll">
                {(item.project_guesses || [])
                  .filter(filterSearch(projectSearch, "title"))
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((project, i) => (
                    <ProcessListCheck
                      key={project.id}
                      ref={(ref) => {
                        if (projectSelection === i && ref) {
                          ref.scrollIntoView();
                        }
                      }}
                    >
                      <input
                        id={"job" + i}
                        type="checkbox"
                        checked={projects.includes(project.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setProjects([...projects, project.id]);
                          } else {
                            setProjects(
                              projects.filter((id) => id !== project.id)
                            );
                          }
                        }}
                      />
                      <label
                        className={projectSelection === i ? "Selected" : ""}
                        htmlFor={"job" + i}
                      >
                        <i
                          className={`fa-solid ${
                            projects.includes(project.id)
                              ? "fa-square-check"
                              : "fa-square"
                          }`}
                        />
                        &nbsp;
                        {project.accountName ? project.accountName + " - " : ""}
                        {project.title}
                      </label>
                    </ProcessListCheck>
                  ))}
                <br />
                <br />
              </FadeToNone>
              <input
                tabIndex={1}
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setProjectSearch(e.target.value);
                }}
                onKeyUp={ListNavigation(
                  projectSelection,
                  setProjectSelection,
                  projectsFilteredList,
                  (found) => {
                    if (projects.includes(found.id)) {
                      setProjects(projects.filter((id) => id !== found.id));
                    } else {
                      setProjects([...projects, found.id]);
                    }
                  }
                )}
              />
            </InputStageWrapper>
          </SearchCard>
        </div>
        <div className="basis-1/4 px-2">
          <SearchCard>
            <span className="text-lg">Vörur</span>
            <InputStageWrapper>
              <FadeToNone className="overflow-y-scroll">
                {productsFilteredList.map((product, i) => (
                  <ProcessListCheck
                    key={product.id}
                    ref={(ref) => {
                      if (productSelection === i && ref) {
                        ref.scrollIntoView();
                      }
                    }}
                  >
                    <input
                      id={"product" + i}
                      type="checkbox"
                      checked={products.includes(product.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setProducts([...products, product.id]);
                        } else {
                          setProducts(
                            products.filter((id) => id !== product.id)
                          );
                        }
                      }}
                    />
                    <label
                      className={productSelection === i ? "Selected" : ""}
                      htmlFor={"product" + i}
                    >
                      <div className="float-left w-7 h-6">
                        {product.logo_url && (
                          <img
                            className="mx-auto h-6"
                            src={product.logo_url + "?h=24"}
                          />
                        )}
                      </div>
                      <i
                        className={`fa-solid ${
                          products.includes(product.id)
                            ? "fa-square-check"
                            : "fa-square"
                        }`}
                      />
                      &nbsp;
                      {product.title}
                    </label>
                  </ProcessListCheck>
                ))}
                <br />
                <br />
              </FadeToNone>
              <input
                tabIndex={1}
                type="text"
                onChange={(e) => {
                  setProductSearch(e.target.value);
                }}
                onKeyUp={ListNavigation(
                  productSelection,
                  setProductSelection,
                  productsFilteredList,
                  (found) => {
                    if (products.includes(found.id)) {
                      setProducts(products.filter((id) => id !== found.id));
                    } else {
                      setProducts([...products, found.id]);
                    }
                  }
                )}
              />
            </InputStageWrapper>
          </SearchCard>
        </div>
        <div className="basis-1/4 px-2">
          <SearchCard>
            <span className="text-lg">Tögg</span>
            <InputStageWrapper>
              <FadeToNone className="overflow-y-scroll max-h-full">
                {tagsFilteredList.map((t, i) => (
                  <ProcessListCheck
                    key={t.id}
                    ref={(ref) => {
                      if (tagSelection === i && ref) {
                        ref.scrollIntoView();
                      }
                    }}
                  >
                    <input
                      id={"tag" + i}
                      type="checkbox"
                      checked={tags.includes(t.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setTags([...tags, t.id]);
                        } else {
                          setTags(tags.filter((id) => id !== t.id));
                        }
                      }}
                    />
                    <label
                      className={tagSelection === i ? "Selected" : ""}
                      htmlFor={"tag" + i}
                    >
                      <i
                        className={`fa-solid ${
                          tags.includes(t.id) ? "fa-square-check" : "fa-square"
                        }`}
                      />
                      &nbsp;
                      {t.name}
                    </label>
                  </ProcessListCheck>
                ))}
                <br />
                <br />
              </FadeToNone>
              <input
                tabIndex={1}
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setTagSearch(e.target.value);
                }}
                onKeyUp={ListNavigation(
                  tagSelection,
                  setTagSelection,
                  tagsFilteredList,
                  (found) => {
                    if (tags.includes(found.id)) {
                      setTags(tags.filter((id) => id !== found.id));
                    } else {
                      setTags([...tags, found.id]);
                    }
                  },
                  () => {
                    //TODO: Create??
                    console.log("Create tag: ", tagSearch);
                  }
                )}
              />
            </InputStageWrapper>
          </SearchCard>
        </div>
      </div>
    </div>
  );
}

export default function ProcessingQueue() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const params = useParams();

  const redirectToNextItem = (id) => {
    if (id && isString(id)) {
      window.location = "/import/import_process/" + id;
      return;
    }
    window.location = "/import";
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Messages />
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {params.id ? (
              <ProcessingItem
                key={params.id}
                id={params.id}
                redirectToNextItem={redirectToNextItem}
              />
            ) : (
              <DataTable
                tablename="human_processing_queue"
                fields={["id", "filename", "datetimeTaken", "gps"]}
                rowsClickable={true}
              />
            )}
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
