import React from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./utils/KurvanAuthContext";
import Sugar from "sugar";
Sugar.extend({
  except: [String],
});
Sugar.String.extend({
  except: ["replaceAll"],
});

var root = createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>
);
