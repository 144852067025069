import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import GoogleMapReact from "google-map-react";
import {
  getZoomLevel,
  getMapCenter,
  defaultCoords,
  MapMarker,
} from "../../utils/GoogleMaps";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Banner from "../../partials/Banner";
import Messages from "./Messages";
import DataTable from "./DataTable";

function ItemPicker({ items }) {
  const [search, setSearch] = useState("");

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div
        className="px-2 bg-white overflow-scroll"
        style={{ height: "300px" }}
      >
        {(items || [])
          .filter((i) => {
            return i.name.toLowerCase().includes(search.toLowerCase());
          })
          .map((i) => (
            <div>{i.name}</div>
          ))}
      </div>
      <div className="px-2 bg-slate-100"></div>
      <div className="bg-slate-100">
        <input
          className="p-2"
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>
    </div>
  );
}

function MissingDataItem({ id }) {
  //const {data:item, loading, setData} = useFirestoreGetDocument('missing_data_queue', id);
  //const {data:stores, storesLoading} = useFirestoreGetAllDocuments("stores");

  if (loading) {
    return <span>Loading</span>;
  }

  if (!item) {
    return <span>item not found</span>;
  }

  //TODO: Import date and user who imported
  //
  //TODO: datetime missing -> Datetimepicker
  //TODO: got gps -> map with point and closest stores(the guesses)
  //TODO: gps or store missing -> store picker

  return (
    <div className="flex flex-row">
      <div className="basis-1/4">
        <img
          src={`https://kurvan-io.imgix.net/import_processing_queue/${item.id}?max-width=350&height=350`}
        />

        <div>
          Imported by:
          <div>{item.user.name}</div>
          <div>{item.user.email}</div>
          <div>{item.dateImported}</div>
        </div>
      </div>
      <div className="basis-1/4 px-2">
        <div className="bg-slate-50">
          <div>
            {item.datetimeTaken ? (
              <span>Datetime Taken: {item.datetimeTaken}</span>
            ) : (
              <span>No datetime taken</span>
            )}
          </div>
          <div>
            {item.gps ? (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                {item.gps.lat},{item.gps.lng}
              </span>
            ) : (
              <span>No gps</span>
            )}
          </div>
        </div>
        {item.gps && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCd0bKIf2AqsdP4jSIyCH3231VGYHjqd6o",
            }}
            {...{
              center: getMapCenter([item.gps || defaultCoords]),
              zoom: item.gps ? getZoomLevel([item.gps], 15) : 6,
            }}
          >
            <MapMarker
              key={item.id}
              placeColor="#005aff"
              {...(item.gps || defaultCoords)}
            ></MapMarker>
          </GoogleMapReact>
        )}
      </div>
      <div className="basis-2/4 px-2">
        datetime picker
        <br />
        <ItemPicker items={storesLoading ? [] : stores} />
        <br />
      </div>
    </div>
  );
}

export default function MissingDataQueue(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const params = useParams();

  const [item, setItem] = useState(undefined);

  return (
    <div className="flex h-screen overflow-hidden">
      <Messages />
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {params.id ? (
              <MissingDataItem id={params.id} />
            ) : (
              <DataTable
                tablename="missing_data_queue"
                fields={["id", "filename", "datetimeTaken", "gps"]}
              />
            )}
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
