import * as React from "react";
import { callKurvan } from "./KurvanAuth";

function useGetAllDocuments(path: string) {
  const [state, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  });

  React.useEffect(() => {
    if (!state.loading) return;

    callKurvan("/admin/" + path)
      .then((r) => {
        var docs = JSON.parse(r);
        setState({
          data: docs,
          loading: false,
          error: null,
        });
      })
      .catch((e) => {
        setState({
          data: null,
          loading: false,
          error: e,
        });
      });
  }, [path, state.loading]);

  const refetch = React.useCallback(() => {
    setState((currentState) => {
      return {
        ...currentState,
        loading: true,
      };
    });
  }, []);

  return { ...state, refetch };
}

function useGetDocumentById(path: string, docId: string) {
  return useGetDocument(`/admin/${path}/${docId}`);
}

function useSetDocumentById(path: string, docId: string) {
  return useSetDocument(`/admin/${path}/${docId}`);
}
function useSetDocument(path: string) {
  return (data) => setDocument(path, data);
}

function setDocument(path, data) {
  return callKurvan(path, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(JSON.parse)
    .catch((e) => {
      console.error(e);
      return null;
    });
}

function useGetDocument(path: string) {
  const [state, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  });

  React.useEffect(() => {
    if (!state.loading) return;
    const abortCtrl = new AbortController();
    const opt = { signal: abortCtrl.signal };

    callKurvan(path, opt)
      .then((r) => {
        var docs = JSON.parse(r);
        setState({
          data: docs,
          error: null,
          loading: false,
        });
      })
      .catch((e) => {
        setState({
          data: null,
          error: e,
          loading: false,
        });
      });

    return () => {
      abortCtrl.abort();
    };
  }, [path, state.loading]);

  const setDocumentData = (data) => {
    return callKurvan(path, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(JSON.parse)
      .then((response) => {
        setState({
          data,
          error: null,
          loading: false,
        });
        return response;
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  };

  return {
    ...state,
    setData: setDocumentData,
    refetch: () =>
      setState({
        ...state,
        loading: true,
      }),
  };
}

function removeDocument(path: string, docId: string) {
  return callKurvan(`/admin/${path}/${docId}`, {
    method: "DELETE",
  })
    .then(console.log)
    .catch(console.log);
}

export {
  useGetAllDocuments,
  useGetDocument,
  useGetDocumentById,
  useSetDocument,
  useSetDocumentById,
  removeDocument,
  setDocument,
};
