import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import { useGetDocumentById } from "../utils/Kurvanstore";
import { useParams } from "react-router-dom";

const emptyCustomer = {
  title: "",
  app_url: "",
};

function CustomerList() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [edited, setEdited] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { id } = useParams();
  const {
    data: customer,
    loading,
    setData,
  } = useGetDocumentById("accounts", id);

  useEffect(() => {
    setFormData(customer || {});
    setEdited(false);
    setSubmitted(false);
  }, [customer]);

  const onSubmit = (e) => {
    e.preventDefault();
    setData(formData);
    setSubmitted(true);
  };

  const updateformData = (fieldName) => (event) => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
    setEdited(true);
    setSubmitted(false);
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Customer &nbsp;
                      <span className="text-sm text-red-600">
                        {edited ? "(Unsaved Changes)" : ""}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This the system information behind each clients to be
                      careful what you edit
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" onSubmit={onSubmit}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-3 gap-6">
                          <div className="col-span-3 sm:col-span-2">
                            <label
                              htmlFor="company-website"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Client url
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                https://
                              </span>
                              <input
                                type="text"
                                name="app_url"
                                id="company-website"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="--.hfg.is"
                                value={formData.app_url}
                                onChange={updateformData("app_url")}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-title"
                              id="company-title"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.title}
                              onChange={updateformData("title")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="about"
                              name="about"
                              rows={3}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Lorem ipsum ..."
                              defaultValue={""}
                              value={formData.description}
                              onChange={updateformData("description")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-logo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Logo image path
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-logo"
                              id="company-logo"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.logo}
                              onChange={updateformData("logo")}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Logo (Not yet implemented)
                          </label>
                          <div className="mt-1 flex items-center">
                            <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                              {formData.logo ? (
                                <img
                                  className="h-full w-full text-gray-300"
                                  src={formData.logo}
                                />
                              ) : (
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              )}
                            </span>
                            <button
                              type="button"
                              className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Change
                            </button>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-top_background"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Background image path
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-top_background"
                              id="company-top_background"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.top_background}
                              onChange={updateformData("top_background")}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Cover photo (Not yet implemented)
                          </label>
                          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              {formData.top_background ? (
                                <img src={formData.top_background} />
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-FUSIONAUTH_API_KEY"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Fusionauth: API_KEY
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-FUSIONAUTH_API_KEY"
                              id="company-FUSIONAUTH_API_KEY"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.FUSIONAUTH_API_KEY}
                              onChange={updateformData("FUSIONAUTH_API_KEY")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-manager_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Manager Name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-manager_name"
                              id="company-manager_name"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.manager_name}
                              onChange={updateformData("manager_name")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-manager_email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Manager email
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-manager_email"
                              id="company-manager_email"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.manager_email}
                              onChange={updateformData("manager_email")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="company-FUSIONAUTH_CLIENT_ID"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Fusionauth: CLIENT_ID
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-FUSIONAUTH_CLIENT_ID"
                              id="company-FUSIONAUTH_CLIENT_ID"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.FUSIONAUTH_CLIENT_ID}
                              onChange={updateformData("FUSIONAUTH_CLIENT_ID")}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="company-FUSIONAUTH_CLIENT_SECRET"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Fusionauth: CLIENT_SECRET
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-FUSIONAUTH_CLIENT_SECRET"
                              id="company-FUSIONAUTH_CLIENT_SECRET"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.FUSIONAUTH_CLIENT_SECRET}
                              onChange={updateformData(
                                "FUSIONAUTH_CLIENT_SECRET"
                              )}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="company-SENDGRID_API_KEY"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sendgrid: API_KEY
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company-SENDGRID_API_KEY"
                              id="company-SENDGRID_API_KEY"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.SENDGRID_API_KEY}
                              onChange={updateformData("SENDGRID_API_KEY")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Link
                          to="/customers"
                          className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {submitted ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}

export default CustomerList;
