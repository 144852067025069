import React, { useState, useEffect } from "react";
import createChannel from "swivel/page";

var swivel;

export default function Messages() {
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState("");

  const handler = (context, message) => {
    setCount(count + 1);
    setMessage(message);
  };

  useEffect(function () {
    console.log(navigator.serviceWorker);
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(function () {
        swivel = createChannel();
        swivel.on("statusMessage", handler);
      });
    }

    return () => {
      if (swivel) swivel.off("statusMessage", handler);
    };
  }, []);

  if (count === 0) return null;

  return (
    <div
      style={{
        height: "40px",
        width: "300px",
      }}
      className="absolute z-40 right-0 bottom-0 text-white bg-gray-800 p-2"
    >
      ({count}) {message}
    </div>
  );
}
