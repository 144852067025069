import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { v1 } from "uuid";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import { useGetAllDocuments } from "../utils/Kurvanstore";
import { callKurvan } from "../utils/KurvanAuth";

export default function Projects() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const { data, loading, refetch } = useGetAllDocuments("projects");
  const { data: allAccounts } = useGetAllDocuments("accounts");

  const updateAccepted = (projectId) => {
    callKurvan(`/admin/projects/${projectId}`, {
      method: "PATCH",
      body: JSON.stringify({
        hasBeenAccepted: true,
      }),
    }).then(() => {
      refetch();
    });
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <select
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                accountId: e.target.value,
                              });
                            }}
                          >
                            <option value="">Sía eftir Viðskiptavini</option>
                            {[...(allAccounts || [])]
                              .sort((a, b) => a.title.localeCompare(b.title))
                              .map((a) => (
                                <option value={a.id}>{a.title}</option>
                              ))}
                          </select>
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {(data || [])
                        .filter((a) => {
                          if (filter.accountId) {
                            return a.accountId == filter.accountId;
                          }
                          return true;
                        })
                        .sort((a, b) => {
                          return (
                            a.hasBeenAccepted - b.hasBeenAccepted ||
                            a.title.trim().localeCompare(b.title.trim())
                          );
                        })
                        .map((project) => (
                          <tr key={project.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  {project.logo ? (
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={project.logo}
                                      alt={project.title}
                                    />
                                  ) : (
                                    <svg viewBox="0 0 448 512">
                                      <path
                                        fill="currentColor"
                                        d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {project.title} ({project.type})
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {project.id}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {(allAccounts || []).find(
                                      (a) => a.id == project.accountId
                                    )?.title ?? "..."}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {project.hasBeenAccepted ? (
                                <span>
                                  <i
                                    className="fas fa-check text-green-500"
                                    title="Missing"
                                  />{" "}
                                  Accepted
                                </span>
                              ) : (
                                <span>
                                  <i
                                    className="fas fa-exclamation text-yellow-400"
                                    title="Requested by client, not yet accepted"
                                  />{" "}
                                  Requested
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {/*<Link to={`/project/${project.id}`} className="text-indigo-600 hover:text-indigo-900">Edit</Link>*/}
                              {!project.hasBeenAccepted && (
                                <button
                                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  onClick={(e) => {
                                    updateAccepted(project.id);
                                  }}
                                >
                                  Accept request
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
