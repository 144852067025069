import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useCurrentUser } from "./KurvanAuth";

import Login from "../pages/Login";
import Loader from "../pages/Loader";

const PrivateRoute = ({ component, ...rest }) => {
  const currentUser = useCurrentUser();

  return currentUser?.id ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
