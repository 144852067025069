import React, { useState, useRef, useEffect, useContext } from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import moment from "moment";
import { Dialog } from "@headlessui/react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import Datepicker from "../partials/actions/Datepicker";
import {
  useGetAllDocuments,
  useGetDocument,
  useSetDocumentById,
} from "../utils/Kurvanstore";
import { callKurvan } from "../utils/KurvanAuth";
import SearchItems from "../utils/SearchItems";
import { uploadImage } from "../utils/Utils";

function Image(props) {
  return <ReactPanZoom image={props.src} />;
}

function AmountInput({ slotCount, product, measure, onChange }) {
  const {
    data: point,
    loading,
    refetch,
  } = useGetDocument(
    `/admin/shelf_analysis_slots/(${product.id}:${measure?.id})`
  );
  useEffect(() => {
    if (!loading) {
      const count = point?.slotCount ?? "0";
      onChange(String(count), refetch);
    }
  }, [point]);

  return (
    <>
      <input
        type="text"
        className="py-1"
        style={{ width: "70px" }}
        value={slotCount?.value ?? "0"}
        onChange={(e) => {
          if (e.target.value == "") {
            onChange("", refetch);
          }
          const num = parseInt(e.target.value);
          if (!isNaN(num)) {
            onChange(String(num), refetch);
          }
        }}
      />
      <div
        className="ml-2 text-center"
        style={{ width: "60px", display: "inline-block" }}
      >
        {loading ? (
          <span>
            <i className="fas fa-spinner" title="Loading..." /> Loading
          </span>
        ) : slotCount?.value === "" ? (
          <span>
            <i className="fas fa-exclamation text-red-400" title="invalid" />{" "}
            invalid
          </span>
        ) : point?.slotCount === undefined ? (
          <span>
            <i className="fas fa-exclamation text-yellow-400" title="New" /> New
          </span>
        ) : point?.slotCount != slotCount?.value ? (
          <span>
            <i className="fas fa-exclamation text-green-400" title="Modified" />{" "}
            Modified
          </span>
        ) : (
          <i className="fas fa-check text-green-400" title="good" />
        )}
      </div>
    </>
  );
}
import { v1 } from "uuid";

function NewMeasurementDialog(props) {
  const id = v1();
  const [isOpen, setIsOpen] = useState(false);
  const setData = useSetDocumentById("area_measurements", id);
  const { data: allStores, loading: storesLoading } =
    useGetAllDocuments("stores");
  const { data: allAreas, loading: areasLoading } =
    useGetAllDocuments("store_areas");

  const [date, setDate] = useState(new Date());
  const [store, setStore] = useState(undefined);
  const [area, setArea] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [imageFile, setImageFile] = useState(undefined);
  const [feedback, setFeedback] = useState(undefined);
  const [newArea, setNewArea] = useState(false);
  const [areaName, setAreaName] = useState("");

  useEffect(() => {
    setFeedback(undefined);
  }, [date, store, area, imageFile]);

  if (storesLoading || areasLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setWorking(true);
    if (!store) {
      setFeedback("Missing store");
      setWorking(false);
      return;
    }
    if (!area && !newArea) {
      setFeedback("Missing area");
      setWorking(false);
      return;
    }
    if (!imageFile) {
      setFeedback("Missing image");
      setWorking(false);
      return;
    }
    const imageId = await uploadImage(
      "/admin/shelf_import_image",
      imageFile,
      store.id
    );
    if (!imageId) {
      setFeedback("Failed to upload image");
      setWorking(false);
      return;
    }

    const data = {
      id,
      date: date.toISOString(),
      storeAreaId: !newArea ? area.id : null,
      storeAreaName: newArea ? areaName : null,
      imageId,
      storeId: store.id,
    };
    const res = await setData(data);
    setWorking(false);
    if (props.onAdd) {
      props.onAdd(res);
      setIsOpen(false);
    }
  };

  const areaOptions = allAreas
    .filter((a) => {
      if (store) {
        return a.storeId == store.id;
      }
      return true;
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <button
        type="button"
        className={
          "inline-flex justify-center py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center"
        }
        onClick={() => setIsOpen(true)}
      >
        Ný mæling
      </button>

      <Dialog
        className="relative z-50"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            className="mx-auto bg-white p-4 shadow-lg rounded-sm border"
            style={{ width: "700px" }}
          >
            <form onSubmit={onSubmit}>
              <Dialog.Title className="pb-2 text-lg font-bold">
                Ný mæling
              </Dialog.Title>
              <div className="grid grid-cols-2">
                <div className="w-310">
                  <Datepicker
                    onChange={(e) => {
                      setDate(e[0]);
                    }}
                  />
                </div>
                <div>
                  <div className="my-1">
                    <input
                      type="file"
                      id="image"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setImageFile(file);
                        }
                      }}
                    />
                  </div>
                  <div className="my-1">
                    <label className="block text-sm font-medium">Búð</label>
                    <select
                      className="w-full"
                      value={store?.id}
                      onChange={(e) => {
                        const foundStore = allStores.find(
                          (s) => s.id == e.target.value
                        );
                        setStore(foundStore);
                      }}
                    >
                      <option key={"N/A"}>Velja Búð</option>
                      {allStores
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {store && !newArea && (
                    <div className="my-1">
                      <label className="block text-sm font-medium">Svæði</label>
                      <select
                        className="w-full"
                        value={area?.id}
                        onChange={(e) => {
                          const found = allAreas.find(
                            (s) => s.id == e.target.value
                          );
                          setArea(found);
                        }}
                      >
                        <option>Velja svæði</option>
                        {areaOptions.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.title} - {s.store.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="rounded-md text-sm py-1 px-2 text-white bg-green-600 hover:bg-green-700"
                        onClick={() => {
                          setNewArea(true);
                        }}
                      >
                        Nýtt
                      </button>
                    </div>
                  )}
                  {newArea && (
                    <div className="my-1">
                      <label className="block text-sm font-medium">Svæði</label>
                      <input
                        className="w-full"
                        type="text"
                        value={areaName}
                        onChange={(e) => setAreaName(e.target.value)}
                      />
                      <button
                        className="rounded-md text-sm py-1 px-2 text-white bg-green-600 hover:bg-green-700"
                        onClick={() => {
                          setNewArea(false);
                        }}
                      >
                        hætta við
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="m-2 text-right">
                {feedback && <div className="text-red-400">* {feedback}</div>}
                <button
                  type="submit"
                  disabled={working}
                  className="rounded-md text-sm py-1 px-2 text-white bg-green-600 hover:bg-green-700"
                >
                  {working ? "...working" : "Vista"}
                </button>
                <button
                  disabled={working}
                  className="rounded-md text-sm ml-1 py-1 px-2 text-white bg-green-600 hover:bg-green-700"
                  onClick={() => setIsOpen(false)}
                >
                  til baka
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}

export default function ShelfAnalysisInput() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data: allProducts, loading: productsLoading } =
    useGetAllDocuments("products");
  const { data: allSubCategories } = useGetAllDocuments("sub_categories");
  const { data: allMeasurements, refetch: measuresRefetch } =
    useGetAllDocuments("area_measurements");

  const [products, setProducts] = useState([]);
  const [measureId, setMeasureId] = useState("");
  const [modifiedCounts, setModifiedCounts] = useState({});

  const [working, setWorking] = useState(false);

  const [feedback, setFeedback] = useState(undefined);
  useEffect(() => {
    setFeedback(undefined);
  }, [products]);

  if (productsLoading) {
    return <span>Loading</span>;
  }

  const measure = allMeasurements?.find((m) => m.id === measureId);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto flex">
            <div className="bg-white rounded-sm mx-1 w-96">
              <div className="p-2">
                <label className="block text-sm font-medium">
                  Undirflokkar
                </label>
                <select
                  className="w-full"
                  onChange={(e) => {
                    const found = allSubCategories.find(
                      (s) => s.id == e.target.value
                    );
                    if (found) {
                      setProducts(found.productIds);
                    } else {
                      setProducts([]);
                    }
                  }}
                >
                  <option>Ekkert valið</option>
                  {(allSubCategories || [])
                    .map((s) => {
                      var option = s.title;
                      if (s.category) {
                        option = `${s.category?.title} - ${s.title}`;
                      }

                      return { ...s, option };
                    })
                    .sort((a, b) => a.option.localeCompare(b.option))
                    .map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.option}
                      </option>
                    ))}
                </select>
              </div>
              <div className="overflow-hidden relative border bg-black w-96">
                {measure && (
                  <Image
                    src={
                      "https://kurvan-io.imgix.net/" +
                      measure.image.bucketPath +
                      "?expire=1683000196"
                    }
                  />
                )}
              </div>
              <div className="bg-white rounded-sm p-2">
                <NewMeasurementDialog
                  onAdd={(data) => {
                    measuresRefetch();
                    setMeasureId(data.id);
                  }}
                />
                <label className="block text-sm font-medium">Mælingar</label>
                <select
                  className="w-full"
                  value={measureId}
                  onChange={(e) => {
                    const found = allMeasurements.find(
                      (s) => s.id == e.target.value
                    );
                    if (found) {
                      setMeasureId(found.id);
                      setProducts(found.productIds);
                      setModifiedCounts({});
                    } else {
                      setMeasureId("");
                      setProducts([]);
                      setModifiedCounts({});
                    }
                  }}
                >
                  <option>Ekkert valið</option>
                  {(allMeasurements || [])
                    //.sort((a,b)=> a.title.localeCompare(b.title))
                    .map((m, i) => (
                      <option value={m.id} key={m.id}>
                        mæling {moment(m.date).format("D/M/Y")} - {m.store.name}{" "}
                        {m.area.title}
                      </option>
                    ))}
                </select>
                <label className="block text-sm font-medium">Vörur</label>
                <SearchItems
                  {...{
                    items: allProducts,
                    selectedItems: products,
                    searchBy: "title",
                    onSelect: (action, found) => {
                      if (action === "toggle") {
                        if (products.includes(found.id)) {
                          setProducts(products.filter((id) => id !== found.id));
                        } else {
                          setProducts([...products, found.id]);
                        }
                      } else if (action === "addAll") {
                        const ids = found.map((o) => o.id);
                        const newProducts = [...products, ...ids].unique();
                        setProducts(newProducts);
                      } else if (action === "removeAll") {
                        const ids = found.map((o) => o.id);
                        setProducts(products.filter((id) => !ids.includes(id)));
                      } else if (action === "clearAll") {
                        setProducts([]);
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-grow px-2">
              <div className="bg-white rounded-sm p-2">
                <div className="basis-2/4 px-2">
                  <div className="bg-white rounded-sm p-2">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-3 py-2">
                            <span className="text-lg">Nafn</span>
                          </th>
                          <th scope="col" className="px-6 py-2">
                            <span className="text-lg">Fjöldi hólfa</span>
                          </th>
                          <th scope="col" className="px-6 py-2">
                            <span className="text-lg"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200  overflow-y-scroll h-120">
                        {allProducts
                          ?.filter((p) => products.includes(p.id))
                          .map((p) => {
                            return (
                              <tr key={p.id}>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">
                                      {p.title}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="ml-4">
                                    <AmountInput
                                      key={measure?.id + p.id}
                                      slotCount={modifiedCounts[p.id]}
                                      measure={measure}
                                      product={p}
                                      onChange={(e, refetch) => {
                                        setModifiedCounts({
                                          ...modifiedCounts,
                                          [p.id]: { value: e, refetch },
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <button
                                    className={`inline-flex rounded-lg justify-center ml-1 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 items-center`}
                                    tabIndex={-1}
                                    onClick={() => {
                                      setProducts(
                                        products.filter((id) => id !== p.id)
                                      );
                                      let newModifiedCounts = {
                                        ...modifiedCounts,
                                      };
                                      delete newModifiedCounts[p.id];
                                      setModifiedCounts(nModifiedCounts);
                                    }}
                                  >
                                    <i className="fas fa-circle-minus" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}

                        <tr key={"last"}>
                          <td className="px-6 py-2 whitespace-nowrap"></td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            <div className="ml-4">
                              Samtals Fjöldi hólfa:{" "}
                              {Object.values(modifiedCounts)
                                .map((v) => parseInt(v.value))
                                .filter((n) => !isNaN(n))
                                .sum()}
                            </div>
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-right">
                    {feedback && (
                      <span className="text-red-400">* {feedback}</span>
                    )}
                    <button
                      type="button"
                      className={
                        "inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center" +
                        (working ? "cursor-wait" : "")
                      }
                      onClick={async () => {
                        if (products.length === 0) {
                          setFeedback("Nothing to send");
                          return;
                        }
                        setWorking(true);
                        const slots = products.map((productId) => {
                          const count = modifiedCounts[productId]?.value || 0;
                          return {
                            //date: date.toISOString(),
                            //storeId: store.id,
                            areaMeasurementId: measure.id,
                            productId: productId,
                            slotCount: parseInt(count),
                          };
                        });
                        callKurvan(`/admin/shelf_analysis_slots`, {
                          method: "POST",
                          body: JSON.stringify(slots),
                        })
                          .then(() => {
                            Object.values(modifiedCounts).map((p) => {
                              p.refetch();
                            });
                            setWorking(false);
                          })
                          .catch((e) => {
                            console.log(e);
                            setWorking(false);
                          });
                      }}
                    >
                      Senda
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
