import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { callKurvan } from "./KurvanAuth";

const AuthContext = React.createContext({});

function getLocalCacheUser() {
  const loggedInUser = localStorage.getItem("user");
  if (loggedInUser && loggedInUser !== "undefined") {
    const foundUser = JSON.parse(loggedInUser);
    return foundUser;
  }

  return null;
}

function setLocalCacheUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

const onAuthStateChanged = (setUser) => {
  const loggedInUser = getLocalCacheUser();
  if (loggedInUser) setUser(loggedInUser);

  callKurvan("/admin/me")
    .then((r) => {
      return JSON.parse(r);
    })
    .then((j) => {
      if (JSON.stringify({}) !== JSON.stringify(j)) {
        localStorage.setItem("user", JSON.stringify(j));
        setUser(j);
      }
    })
    .catch((e) => {
      console.log(e);
      var user = {};
      setLocalCacheUser(user);

      setUser(user);
    });
};

const useLocationIntervalEvent = (interval) => {
  const [lastUpdate, setLastUpdate] = useState(0);

  useEffect(() => {
    const currentTime = Date.now();
    if (!lastUpdate || currentTime - lastUpdate > interval) {
      setLastUpdate(currentTime);
    }
  }, []);

  return lastUpdate;
};

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(getLocalCacheUser() || {});
  const lastTime = useLocationIntervalEvent();

  React.useEffect(() => {
    onAuthStateChanged(setCurrentUser);
  }, [lastTime]);

  const wrappedSetUser = (user) => {
    setCurrentUser(user);
    setLocalCacheUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser: wrappedSetUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useKurvanAuth must be used within a KurvanAuthProvider");
  }
  return context;
}

export {
  AuthContext,
  AuthProvider,
  useAuth,
  getLocalCacheUser,
  setLocalCacheUser,
};
