import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import "./charts/ChartjsConfig";
import PrivateRoute from "./utils/PrivateRoute";

// Import pages
import Dashboard from "./pages/Dashboard";
import CustomerList from "./pages/CustomerList";
import Customer from "./pages/Customer";
import Settings from "./pages/Settings";
import Images from "./pages/Images";
import Franchises from "./pages/Franchises";
import Franchise from "./pages/Franchise";
import Store from "./pages/Store";
import Stores from "./pages/Stores";
import Dates from "./pages/Dates";
import Date from "./pages/Date";
import Login from "./pages/Login";
import Import from "./pages/Import";
import MissingDataQueue from "./pages/Import/MissingDataQueue";
import HumanProcessingQueue from "./pages/Import/HumanProcessingQueue";
import PriceSurveyProcess from "./pages/PriceSurveyProcess";
import PriceSurveyProcessV2 from "./pages/PriceSurveyProcessV2";
import ShelfAnalysisProcess from "./pages/ShelfAnalysisProcess";

import Categories from "./pages/Categories";
import Category from "./pages/Category";
import SubCategories from "./pages/SubCategories";
import SubCategory from "./pages/SubCategory";
import Trademarks from "./pages/Trademarks";
import Trademark from "./pages/Trademark";
import Suppliers from "./pages/Suppliers";
import Supplier from "./pages/Supplier";
import Projects from "./pages/Projects";

import Products from "./pages/Products";
import Product from "./pages/Product";

import Measurements from "./pages/Measurements";
import Measurement from "./pages/Measurement";

import Tags from "./pages/Tags";
import Tag from "./pages/Tag";

import Points from "./pages/PriceSurveyPointsList";

function App() {
  /*
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change
  */

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/tag/:id" element={<Tag />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/customers" element={<CustomerList />} />
          <Route path="/customers/:id" element={<Customer />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/images" element={<Images />} />
          <Route path="/franchises" element={<Franchises />} />
          <Route path="/franchise/:id" element={<Franchise />} />
          <Route path="/store/:id" element={<Store />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/date/:id" element={<Date />} />
          <Route path="/dates" element={<Dates />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/sub_category/:id" element={<SubCategory />} />
          <Route path="/sub_categories" element={<SubCategories />} />
          <Route path="/trademark/:id" element={<Trademark />} />
          <Route path="/trademarks" element={<Trademarks />} />
          <Route path="/supplier/:id" element={<Supplier />} />
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/products" element={<Products />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/measurements" element={<Measurements />} />
          <Route path="/measurement/:id" element={<Measurement />} />

          <Route path="/price_survey_points" element={<Points />} />

          <Route path="/import" element={<Import />} />
          <Route
            path="/import/missing_data_queue/:id?"
            element={<MissingDataQueue />}
          />
          <Route
            path="/import/import_process/:id?"
            element={<HumanProcessingQueue />}
          />

          <Route path="/pricesurvey" element={<PriceSurveyProcessV2 />} />
          <Route path="/shelf_analysis" element={<ShelfAnalysisProcess />} />
          <Route path="/pricesurvey_old" element={<PriceSurveyProcess />} />
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
