import { useState } from "react";
import { useAuth } from "./KurvanAuthContext";
import globals from "../globals";

function processChunkedResponse(response) {
  if (!response.body) {
    return response;
  }
  var text = "";
  var reader = response.body.getReader();
  var decoder = new TextDecoder();
  return readChunk();

  function readChunk() {
    return reader.read().then(appendChunks);
  }

  function appendChunks(result) {
    var chunk = decoder.decode(result.value || new Uint8Array(), {
      stream: !result.done,
    });
    text += chunk;
    if (result.done) {
      return text;
    } else {
      return readChunk();
    }
  }
}

const baseOptions = {
  mode: "cors",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

function isChunked(res) {
  const encoding = res.headers.get("Transfer-Encoding");
  return !res.headers.has("Content-Length") && encoding == "chunked";
}

export function callKurvan(path, specific_options) {
  var options = {
    ...baseOptions,
    ...specific_options,
  };

  return fetch(globals.backend_url + path, options).then(async (response) => {
    if (response.status === 0) {
      throw new Error("Getting status 0, possibly cors error");
    }

    if (response.status >= 500) {
      const error = await response.text();
      throw new Error(error);
    }

    if (isChunked(response)) {
      return processChunkedResponse(response);
    }

    return response.text();
  });
}

function get_cookie(name) {
  return (
    document.cookie.split("; ").find((row) => row.startsWith(name + "=")) ?? ""
  ).split("=")[1];
}

function set_cookie(name, value) {
  document.cookie = name + "=" + value + "; Path=/;";
}
function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function useSignInWithEmail() {
  const { currentUser, setCurrentUser } = useAuth();
  return async (email, password) => {
    var authOptions = {
      method: "POST",
      body: JSON.stringify({ email, password }),
    };

    const token = await callKurvan("/admin/login", authOptions)
      .then((text) => JSON.parse(text).token)
      .catch((e) => {
        console.log("Error catch", e);
        return null;
      });
    if (!token) {
      setCurrentUser({});
      return;
    }

    const result = await callKurvan(
      "/callback?no_redirect&access_token=" + token,
      { redirect: "manual" }
    )
      .then(() => true)
      .catch((e) => {
        console.log("Error catch", e);
        return false;
      });
    if (!result) {
      setCurrentUser({});
      return;
    }

    const user = await callKurvan("/admin/me")
      .then((r) => {
        return JSON.parse(r);
      })
      .catch(() => {});
    setCurrentUser(user);
  };
}

function useSignInWithGoogle() {
  //const {auth} = useFirebaseAuth();
  //return () => signInWithPopup(auth, provider)
  return () => {
    throw new Error("Not Implemented");
  };
}

function useAuthSignOut() {
  const { currentUser, setCurrentUser } = useAuth();
  return () => {
    callKurvan("/admin/logout").then(() => {
      delete_cookie("kurvan_token");
      setCurrentUser(null);
    });
  };
}
function useCurrentUser() {
  const [user, SetUser] = useState();
  const context = useAuth();

  return context.currentUser;
}

export {
  useSignInWithEmail,
  useSignInWithGoogle,
  useAuthSignOut,
  useCurrentUser,
};
