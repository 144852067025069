import React, { useState, useRef, useEffect, useContext } from "react";
import { openDB } from "idb";
import { useNavigate } from "react-router-dom";
import { v1 } from "uuid";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { useCurrentUser } from "../utils/KurvanAuth";
import { useGetAllDocuments, removeDocument } from "../utils/Kurvanstore";
import { callKurvan } from "../utils/KurvanAuth";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import Messages from "./Import/Messages";
import DataTable from "./Import/DataTable";

function TriggerPubSub(data) {
  const url =
    process.env.NODE_ENV == "development"
      ? "http://localhost:5001/kurvan-io/europe-west1/TriggerPubSub"
      : "https://europe-west1-kurvan-io.cloudfunctions.net/TriggerPubSub";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "api-key": "a1c72283-84b0-4a52-8034-f43a6e30c7be",
    },
  });
}

const HudBox = styled.div`
  display: inline-block;
  padding: 20px;
  text-align: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  background: white;
  > div {
    font-size: 2em;
  }
  > label {
    font-size: 0.8em;
  }
  & + & {
    margin-left: 10px;
  }
`;

function HudNumber({ title, number }) {
  return (
    <HudBox>
      <div>{number.toLocaleString(undefined)}</div>
      <label>{title}</label>
    </HudBox>
  );
}

function QueueHudNumber({ tablename, title, refetchInterval }) {
  const { data, loading, refetch } = useGetAllDocuments(tablename);
  const list = (data || []).map((i) => JSON.parse(i.data));

  useEffect(() => {
    if (refetchInterval === undefined) refetchInterval = 5;

    if (!refetchInterval || refetchInterval < 1) return;

    const intervalDuration = parseInt(refetchInterval) * 1000;

    const interval = setInterval(() => {
      refetch();
    }, intervalDuration);
    return () => clearInterval(interval);
  }, [tablename, refetchInterval]);

  return <HudNumber title={title} number={!data ? "..." : list.length} />;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

async function uploadFile(fileObject) {
  const file = fileObject.file;

  const data = await getBase64(file);

  const j = {
    id: fileObject.id,
    filename: file.name,
    user: fileObject.user,
    dateImported: fileObject.dateImported,
    byteLength: file.size,
    stringLength: data.length,
    data,
  };
  const bodydata = JSON.stringify(j);
  const url =
    process.env.NODE_ENV == "development"
      ? "http://functions.kurvan.local/kurvan-io/europe-west1/importUploadFile"
      : "https://europe-west1-kurvan-io.cloudfunctions.net/importUploadFile";
  return fetch(url, {
    method: "POST",
    body: bodydata,
    headers: {
      "Content-Type": "application/json",
      "api-key": "a1c72283-84b0-4a52-8034-f43a6e30c7be",
    },
  })
    .then((response) => {
      if (!response.ok) {
        console.log("not okey", response);
        return null;
      }
      console.log(response);
      return response;
    })
    .catch((e) => {
      console.log("error", e);
      return null;
    });
}

const storeName = "uploadRequestFiles";
const dbPromise = openDB("hfg-admin", 1, {
  async upgrade(db, oldVersion) {
    if (oldVersion < 1) {
      db.createObjectStore(storeName, {
        keyPath: "id",
        autoIncrement: true,
      });
    }
  },
});

function IdbHudNumber({ tablename, title, refetchInterval }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (refetchInterval === undefined) refetchInterval = 5;

    if (!refetchInterval || refetchInterval < 1) return;

    const intervalDuration = parseInt(refetchInterval) * 1000;

    const interval = setInterval(async () => {
      const c = await dbPromise.then((db) => db.count(tablename));
      setCount(c);
    }, intervalDuration);
    return () => clearInterval(interval);
  }, [tablename, refetchInterval]);

  return <HudNumber title={title} number={count} />;
}

export default function Import() {
  const navigate = useNavigate();
  const show = true;
  const currentUser = useCurrentUser();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [onlyMine, setOnlyMine] = useState(false);
  const inputFile = useRef(null);

  const insertIntoDatabase = async (files) => {
    var errors = [];
    for (var i = 0; i < files.length; i++) {
      const file = files.item(i);
      await dbPromise
        .then((db) => {
          return db.add(storeName, {
            id: v1(),
            user: currentUser,
            dateImported: new Date().toISOString(),
            file,
          });
        })
        .catch((e) => {
          errors.push(e);
        });
    }

    if (errors.length) {
      console.log(errors);
      NotificationManager.Warning(`Some files are where not able to upload!`);
    }

    NotificationManager.success(
      `Files have been added to background processing. You can continue browsing or using the system now.`
    );
  };

  const submitFiles = async (files) => {
    //save files in browser database
    if (window.isServiceWorkerReady) {
      await insertIntoDatabase(files);
      triggerFileUpload();
    } else {
      var allCount = files.length;
      var count = 0;
      for (var file of files) {
        const fileObject = {
          id: v1(),
          user: currentUser,
          dateImported: new Date().toISOString(),
          file,
        };
        await uploadFile(fileObject);
        count += 1;
        console.log(`STATUS: Uploading ${count}/${allCount}`);
      }

      NotificationManager.success(
        `The files have been processed. You can continue browsing or using the system now.`
      );
    }
  };

  const triggerFileUpload = () => {
    if (window.isServiceWorkerReady) {
      window.myServiceWorker.sync
        .register("upload-files-background")
        .then(function () {
          console.log("Registered!");
        })
        .catch(function (e) {
          console.log("Catched error: ", e);
        });
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Messages />
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <IdbHudNumber
              tablename="uploadRequestFiles"
              title="Myndir í vafra"
              refetchInterval={5}
            />
            <QueueHudNumber
              tablename="import_processing_queue"
              title="Vinnsluröð"
              refetchInterval={10}
            />
            <QueueHudNumber
              tablename="human_processing_queue"
              title="Handavinnuröð"
              refetchInterval={10}
            />
            <br />
            <br />
            <React.Fragment>
              <button
                className="inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center"
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                Hlaða myndum inn
                <i className="fas fa-upload pl-1" />
              </button>
              <button
                className="inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center"
                onClick={() => {
                  setOnlyMine(true);
                }}
              >
                Bara mitt
                <i className="fas fa-upload pl-1" />
              </button>
              <button
                className="inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center"
                onClick={() => {
                  setOnlyMine(false);
                }}
              >
                Allt
                <i className="fas fa-upload pl-1" />
              </button>
              
              
              {show && (
                <button
                  className="inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center"
                  onClick={() => {
                    triggerFileUpload();
                  }}
                  title="Þetta endurræsir upphleðslu ferlið til að sendir myndirnar sem eru vistaðar í vafra yfir í Vinnsluröðina á bakendanum"
                >
                  Endurræsa upphleðslu
                </button>
              )}

              <input
                ref={inputFile}
                style={{ display: "none" }}
                id="upload"
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => {
                  const files = e.target.files;
                  submitFiles(files);
                  NotificationManager.warning(
                    `Processing ${files.length} files. Please stay on the page...`
                  );
                }}
              />
            </React.Fragment>

            <DataTable
              title="Handavinnuröð"
              tablename="human_processing_queue"
              fields={["Nafn myndar", "Tími tekinn", "Hefur hnit", "Notandi", ""]}
              rowsClickable={true}
              expandable={true}
              beginExpanded={true}
              refetchInterval={10}
              filter={(list)=>{
                if(onlyMine){
                  return list.filter(i => i.data.user.id == currentUser.id);
                }
                return list
              }}
              reprocess={async (image, refetch) => {
                try {
                  await callKurvan(
                    `/admin/human_processing_queue/reprocess/${image.id}`
                  );
                } catch (e) {
                  NotificationManager.error(
                    `Ekki tókst að setja aftur í vinnsluröð: ${e.message}`,
                    "Server error"
                  );
                  return;
                }
                try {
                  await TriggerPubSub({
                    topic: "import_process",
                    id: image.id,
                  });
                } catch (e) {
                  refetch();
                  NotificationManager.warning(
                    `Tókst ekki að senda ${image.id} af stað: ${e.message}`,
                    "Vinnsluraðar villa"
                  );
                  return;
                }
                refetch();
                NotificationManager.info(`Endurvinnsla hafinn á ${image.id}`);
              }}
            >
              {({ item, refetch, props }) => (
                <tr
                  onClick={() => navigate(`/import/import_process/${item.id}`)}
                >
                  <td>
                    <img
                      className="h-8 mx-auto"
                      src={`https://kurvan-io.imgix.net/import_folder/${item.data.id}?h=32`}
                    />
                  </td>
                  <td>{item.data.filename}</td>
                  <td>{item.data.datetimeTaken ?? "vantar"}</td>
                  <td>{item.data.gps ? "has" : "Vantar"}</td>
                  <td>{item.data.user.name}</td>
                  <td>
                    <i
                      className="fa-solid fa-rotate text-gray-500 hover:text-gray-800 p-4"
                      title="Senda í vinnsluröð"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        props.reprocess(item.data, refetch);
                      }}
                    />
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-trash text-red-500 hover:text-red-800 p-4"
                      title="Eyða"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeDocument("human_processing_queue", item.id);
                        refetch();
                        NotificationManager.warning(
                          `Eyddi mynd ${item.data.filename}`
                        );
                      }}
                    />
                  </td>
                </tr>
              )}
            </DataTable>

            <DataTable
              title="Vinnsluröð"
              tablename="import_processing_queue"
              fields={["filename", "error"]}
              refetchInterval={5}
              beginExpanded={true}
              onRowClick={async (image) => {
                NotificationManager.info(`Endurvinnsla hafinn á ${image.id}`);
                try {
                  await TriggerPubSub({
                    topic: "merged_import_process",
                    id: image.id,
                  });
                } catch (e) {
                  console.log(e);
                  NotificationManager.warning(
                    `Tókst ekki að senda ${image.id} af stað: ${e.message}`,
                    "Vinnsluraðar villa"
                  );
                  return;
                }
              }}
            >
              {({ item, refetch, onRowClick, props }) => (
                <tr onClick={() => onRowClick(item)}>
                  <td>
                    <img
                      className="h-8 mx-auto"
                      src={`https://kurvan-io.imgix.net/import_folder/${item.data.id}?h=32`}
                    />
                  </td>
                  <td>{item.data.filename}</td>
                  <td className="text-red-600">{item.error}</td>
                  <td>
                    <i
                      className="fa-solid fa-trash text-red-500 hover:text-red-800 p-4"
                      title="Eyða"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeDocument("import_processing_queue", item.id);

                        refetch();
                        NotificationManager.warning(
                          `Eyddi mynd ${item.data.filename}`
                        );
                      }}
                    />
                  </td>
                </tr>
              )}
            </DataTable>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
