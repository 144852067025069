import React, { useState, useRef, useEffect, useContext } from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import styled from "styled-components";
import moment from "moment";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import Datepicker from "../partials/actions/Datepicker";
import {
  useGetAllDocuments,
  useGetDocument,
  useGetDocumentById,
} from "../utils/Kurvanstore";
import { callKurvan } from "../utils/KurvanAuth";
import SearchItems from "../utils/SearchItems";

function Image(props) {
  return <ReactPanZoom image={props.src} />;
}

function LastPrice({ product, store, date, onClickPrice }) {
  const date_string = date.toLocaleDateString().replaceAll("/", "-");
  const {
    data: last_point,
    loading: last_loading,
    error,
  } = useGetDocument(
    `/admin/price_survey_points/last_point/(${product.id}:${store?.id}:${date_string})`
  );
  const last_date = last_point?.date ?? "";
  return (
    <span
      className={last_point && "cursor-pointer"}
      title={moment(last_date).format("D. MMM YYYY")}
      onClick={() => {
        if (last_point) {
          onClickPrice(last_point.price);
        }
      }}
    >
      {error ? "error" : last_point ? last_point.price : "..."}
    </span>
  );
}

function PriceInput({ price, product, store, date, onChange }) {
  const date_string = date.toLocaleDateString().replaceAll("/", "-");
  const {
    data: point,
    loading,
    refetch,
    error,
  } = useGetDocument(
    `/admin/price_survey_points/(${product.id}:${store?.id}:${date_string})`
  );
  useEffect(() => {
    if (!loading) {
      if (point && point.missing) {
        onChange("", refetch);
      } else {
        const price = point?.price ?? "";
        onChange(String(price), refetch);
      }
    }
  }, [point]);

  return (
    <>
      <input
        type="text"
        tabIndex={0}
        className="text-center py-1"
        value={price?.value ?? ""}
        style={{ width: "80px" }}
        onChange={(e) => {
          if (e.target.value == "") {
            onChange("", refetch);
          }
          const num = parseInt(e.target.value);
          if (!isNaN(num)) {
            onChange(String(num), refetch);
          }
        }}
      />
      <div
        className="ml-2 text-center"
        style={{ width: "60px", display: "inline-block" }}
      >
        {loading ? (
          <span>
            <i className="fas fa-spinner" title="Loading..." /> Loading
          </span>
        ) : error ? (
          <span className="text-red-400">
            <i className="fas fa-exclamation" /> error
          </span>
        ) : price?.value === "" ? (
          <span>
            <i className="fas fa-exclamation text-green-400" title="Missing" />{" "}
            Missing
          </span>
        ) : point?.price === undefined ? (
          <span>
            <i className="fas fa-exclamation text-yellow-400" title="New" /> New
          </span>
        ) : point?.price != price?.value ? (
          <span>
            <i className="fas fa-exclamation text-green-400" title="Modified" />{" "}
            Modified
          </span>
        ) : (
          <i className="fas fa-check text-green-400" title="good" />
        )}
      </div>
      <div className="ml-2" style={{ width: "60px", display: "inline-block" }}>
        {error && (
          <button
            tabIndex={-1}
            className={`inline-flex rounded-lg justify-center ml-1 py-1 px-2 border border-transparent shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center`}
            onClick={() => refetch()}
            title="Endursækja"
          >
            <i className="fas fa-rotate" />
          </button>
        )}
      </div>
    </>
  );
}

export default function PriceSurveyExcel() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("titleDesc");
  const { data: allProducts, loading: productsLoading } =
    useGetAllDocuments("products");
  const { data: allStores, loading: storesLoading } =
    useGetAllDocuments("stores");
  const { data: allSurveys } = useGetAllDocuments("price_surveys");
  const { data: allSubCategories } = useGetAllDocuments("sub_categories");

  const [date, setDate] = useState(new Date());
  const [store, setStore] = useState(undefined);
  const [products, setProducts] = useState([]);
  const [modifiedPrices, setModifiedPrices] = useState({});
  const [feedback, setFeedback] = useState(undefined);

  const [working, setWorking] = useState(false);
  const [errorHighlight, setErrorHightlight] = useState([]);
  const [focus, setFocus] = useState("stores");

  useEffect(() => {
    setFeedback(undefined);
  }, [date, store, products]);

  useEffect(() => {
    const len = allStores?.length ?? 0;
    if (len > 0) {
      const first = allStores
        .sort((a, b) => a.name.localeCompare(b.name))
        .at(0);
      setStore(first);
    }
  }, [allStores]);

  useEffect(() => {
    setModifiedPrices({});
  }, [date, store]);

  if (productsLoading || storesLoading) {
    return <span>Loading</span>;
  }

  const toggle = (product, store, date) => (e) => {
    const price = modifiedPrices[product.id];
    var newPrices = {
      ...modifiedPrices,
      [product.id]: {
        price: price.value == "missing" ? "" : "missing",
        refetch: price.refetch,
      },
    };

    setModifiedPrices(newPrices);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="overflow-y-scroll">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto flex">
            <div className="bg-white px-2 w-96">
              <div className="p-2">
                <label className="block text-sm font-medium">
                  Verðkannanir
                </label>
                <select
                  className="w-full"
                  onChange={(e) => {
                    const found = allSurveys.find(
                      (s) => s.id == e.target.value
                    );
                    if (found) {
                      setProducts(found.productIds);
                    } else {
                      setProducts([]);
                    }
                  }}
                >
                  <option>Ekkert valið</option>
                  {(allSurveys || [])
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((s) => (
                      <option value={s.id}>{s.title}</option>
                    ))}
                </select>
                <label className="block text-sm font-medium">
                  Undirflokkar
                </label>
                <select
                  className="w-full"
                  onChange={(e) => {
                    const found = allSubCategories.find(
                      (s) => s.id == e.target.value
                    );
                    if (found) {
                      setProducts(found.productIds);
                    } else {
                      setProducts([]);
                    }
                  }}
                >
                  <option>Ekkert valið</option>
                  {(allSubCategories || [])
                    .map((s) => {
                      var option = s.title;
                      if (s.category) {
                        option = `${s.category?.title} - ${s.title}`;
                      }

                      return { ...s, option };
                    })
                    .sort((a, b) => a.option.localeCompare(b.option))
                    .map((s) => (
                      <option value={s.id}>{s.option}</option>
                    ))}
                </select>
              </div>
              <hr />
              <div className="p-2">
                <label className="block text-sm font-medium">Búð</label>
                <select
                  value={store?.id}
                  onChange={(e) => {
                    const foundStore = allStores.find(
                      (s) => s.id == e.target.value
                    );
                    setStore(foundStore);
                  }}
                >
                  <option>Velja Búð</option>
                  {(allStores || [])
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((s) => (
                      <option value={s.id}>{s.name}</option>
                    ))}
                </select>

                <Datepicker
                  onChange={(e) => {
                    setDate(e[0]);
                    setFocus("stores");
                  }}
                />

                <label className="mt-2 block text-sm font-medium">Vörur</label>
                <SearchItems
                  {...{
                    items: allProducts,
                    selectedItems: products,
                    searchBy: "title",
                    onSelect: (action, found) => {
                      if (action == "toggle") {
                        if (products.includes(found.id)) {
                          setProducts(products.filter((id) => id !== found.id));
                        } else {
                          setProducts([...products, found.id]);
                        }
                      } else if (action == "addAll") {
                        const ids = found.map((o) => o.id);
                        const newProducts = [...products, ...ids].unique();
                        setProducts(newProducts);
                      } else if (action == "removeAll") {
                        const ids = found.map((o) => o.id);
                        setProducts(products.filter((id) => !ids.includes(id)));
                      } else if (action == "clearAll") {
                        setProducts([]);
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-grow px-2">
              <div className="bg-white rounded-sm p-2">
                <div className="basis-2/4 px-2">
                  <div className="bg-white rounded-sm p-2">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-3 py-3">
                            <span
                              className="text-lg cursor-pointer"
                              onClick={() => {
                                if (sortOrder === "titleAsc") {
                                  setSortOrder("titleDesc");
                                } else {
                                  setSortOrder("titleAsc");
                                }
                              }}
                            >
                              Nafn
                            </span>
                          </th>
                          <th scope="col" className="px-2 py-3">
                            <span className="text-lg">Síðasta mæling</span>
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <span className="text-lg">Verð</span>
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <span className="text-lg"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {allProducts
                          .filter((p) => products.includes(p.id))
                          .sort((a, b) => {
                            if (sortOrder === "titleAsc") {
                              return a.title.localeCompare(b.title);
                            } else if (sortOrder === "titleDesc") {
                              return b.title.localeCompare(a.title);
                            }
                          })
                          .map((p) => {
                            return (
                              <tr key={p.id}>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">
                                      {p.title}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="ml-4">
                                    <LastPrice
                                      key={p.id + store.id + date}
                                      product={p}
                                      store={store}
                                      date={date}
                                      onClickPrice={(price) => {
                                        setModifiedPrices({
                                          ...modifiedPrices,
                                          [p.id]: { value: String(price) },
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="ml-4">
                                    <PriceInput
                                      key={p.id + store.id + date}
                                      price={modifiedPrices[p.id]}
                                      product={p}
                                      store={store}
                                      date={date}
                                      onChange={(e, refetch) => {
                                        console.log(e);
                                        setModifiedPrices({
                                          ...modifiedPrices,
                                          [p.id]: { value: e, refetch },
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <button
                                    tabIndex={-1}
                                    className={`inline-flex rounded-lg justify-center ml-1 py-1 px-2 border border-transparent shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center`}
                                    onClick={toggle(p, store, date)}
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                  <button
                                    tabIndex={-1}
                                    className={`inline-flex rounded-lg justify-center ml-1 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 items-center`}
                                    onClick={() => {
                                      setProducts(
                                        products.filter((id) => id !== p.id)
                                      );
                                      let nModifiedPrices = {
                                        ...modifiedPrices,
                                      };
                                      delete nModifiedPrices[p.id];
                                      setModifiedPrices(nModifiedPrices);
                                    }}
                                  >
                                    <i className="fas fa-circle-minus" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-right">
                    <span className="text-green-800">
                      {
                        Object.values(modifiedPrices).filter((i) => i?.value)
                          .length
                      }{" "}
                      af {products.length} með verð
                    </span>
                    {feedback && (
                      <span className="text-red-400">* {feedback}</span>
                    )}
                    <button
                      type="button"
                      className={
                        "inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center" +
                        (working ? "cursor-wait" : "")
                      }
                      onClick={async () => {
                        if (!date) {
                          setFeedback("date missing");
                          return;
                        }
                        if (!store) {
                          setFeedback("store missing");
                          return;
                        }
                        if (products.length == 0) {
                          setFeedback("No products have been added");
                          return;
                        }
                        setWorking(true);
                        const pricePoints = products.map((productId) => {
                          const price =
                            modifiedPrices[productId]?.value || "missing";
                          const missing = price === "missing";
                          return {
                            date: date.toISOString(),
                            storeId: store.id,
                            productId: productId,
                            price: missing ? 0 : parseInt(price),
                            missing,
                          };
                        });
                        callKurvan(`/admin/price_survey_points`, {
                          method: "POST",
                          body: JSON.stringify(pricePoints),
                        })
                          .then(() => {
                            Object.values(modifiedPrices).map((p) => {
                              console.log(p);
                              if (p.refetch) p.refetch();
                            });
                            setWorking(false);
                          })
                          .catch((e) => {
                            setFeedback("Got error uploading prices");
                            console.log(e);
                            setWorking(false);
                          });
                      }}
                    >
                      {working ? "Vinna..." : "Senda"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
