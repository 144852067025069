import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";

import {
  useGetDocumentById,
  useSetDocumentById,
  useGetAllDocuments,
  removeDocument,
} from "../../utils/Kurvanstore"
export default function EditAreaDialog(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [working, setWorking] = useState(false);
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState(undefined);

  const {
    data: area,
    loading,
    setData,
  } = useGetDocumentById("store_areas", props.id);

  useEffect(() => {
    if (!loading && area) {
      setName(area.title);
    }
  }, [area]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setWorking(true);
    const data = {
      ...area,
      title: name,
    };
    const res = await setData(data);
    setWorking(false);
    if (props.onAdd) {
      props.onAdd(res);
      setIsOpen(false);
    }
    return false;
  };

  return (
    <>
      <button
        type="button"
        className={
          "inline-flex justify-center py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 items-center"
        }
        onClick={() => setIsOpen(true)}
      >
        Breyta Svæði
      </button>

      <Dialog
        className="relative z-50"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            className="mx-auto bg-white p-4 shadow-lg rounded-sm border"
            style={{ width: "700px" }}
          >
            <form onSubmit={onSubmit}>
              <Dialog.Title className="pb-2 text-lg font-bold">
                Ný mæling
              </Dialog.Title>

              <div className="my-1">
                <label className="block text-sm font-medium">Nafn</label>
                <input
                  disabled={loading}
                  className="w-full"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="m-2 text-right">
                {feedback && <div className="text-red-400">* {feedback}</div>}
                <button
                  type="submit"
                  disabled={working}
                  className="rounded-md text-sm py-1 px-2 text-white bg-green-600 hover:bg-green-700"
                >
                  {working ? "...working" : "Vista"}
                </button>
                <button
                  disabled={working}
                  className="rounded-md text-sm ml-1 py-1 px-2 text-white bg-green-600 hover:bg-green-700"
                  onClick={() => setIsOpen(false)}
                >
                  til baka
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
