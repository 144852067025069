import React, { useState, useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSignInWithEmail, useSignInWithGoogle } from "../utils/KurvanAuth";

import { useCurrentUser } from "../utils/KurvanAuth";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";

import background from "../images/markus-spiske-Skf7HxARcoc-unsplash.jpg";

function Login() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const signIn = useSignInWithEmail();
  const signInWithGoogle = useSignInWithGoogle();

  const currentUser = useCurrentUser();
  useEffect(() => {
    if (currentUser?.id) {
      navigate("/");
    }
  }, [currentUser]);

  const googleSignIn = (e) => {
    e.preventDefault();
    console.log("Signing in with google");
    signInWithGoogle();
  };

  const handleSignin = useCallback(
    (event) => {
      setLoggingIn(true);
      event.preventDefault();
      const { email, password } = event.target.elements;

      try {
        const a = signIn(email.value, password.value);
        a.then((event) => {
          navigate("/");
          setLoggingIn(false);
        }).catch((error) => {
          setLoggingIn(false);
          setError(error.code);
          console.log({
            name: error.name,
            message: error.message,
            code: error.code,
            stack: error.stack,
          });
        });
      } catch (error) {
        setLoggingIn(false);
        console.log({
          name: error.name,
          message: error.message,
          code: error.code,
          stack: error.stack,
        });
      }
    }
    //,
    //[history]
  );

  return (
    <div className="">
      <div className="flex justify-center px-6 my-12">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          <div
            className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
            style={{
              backgroundImage: "url(" + background + ")",
            }}
          ></div>
          <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
            <h3 className="pt-4 text-2xl text-center">
              HFG Management console Login
            </h3>
            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
              onSubmit={handleSignin}
            >
              <div className="mb-4">
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="email"
                >
                  Email <span style={{ color: "red" }}>{error || ""}</span>
                </label>
                <input
                  className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="username"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="******************"
                  autoComplete="current-password"
                />
              </div>
              <div className="mb-6 text-center">
                <input
                  className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  type="submit"
                  value={loggingIn ? "Loading..." : "Login"}
                />
                <button
                  className="uppercase h-12 mt-3 text-white w-full rounded-full bg-red-800 hover:bg-red-900"
                  onClick={googleSignIn}
                >
                  <i className="fa fa-google"></i>
                  &nbsp; Google
                </button>
              </div>
              <hr className="mb-6 border-t" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
