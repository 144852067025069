import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { v1 } from "uuid";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import { useGetAllDocuments, removeDocument } from "../utils/Kurvanstore";

export default function Products() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const { data, loading, refetch } = useGetAllDocuments("products");

  const categories = data
    ?.map((p) => ({ id: p.category_id, title: p.category_title }))
    .filter((c) => c.id && c.title)
    .unique((p) => p.id);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                          <Link
                            to={`/product/${v1()}`}
                            className="inline-flex justify-center ml-4 py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            New
                          </Link>
                        </th>
                        <th></th>
                        <th scope="col" className="relative px-6 py-3">
                          <select
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                categoryId: e.target.value,
                              });
                            }}
                          >
                            <option value="">Sía eftir flokk</option>
                            {categories
                              ?.sort((a, b) => a.title.localeCompare(b.title))
                              .map((a) => (
                                <option value={a.id}>{a.title}</option>
                              ))}
                          </select>
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {(data || [])
                        .filter((p) => {
                          if (filter.categoryId) {
                            return p.category_id == filter.categoryId;
                          }
                          return true;
                        })
                        .sort((a, b) => {
                          try {
                            if (!a.title || !b.title) return 0;

                            return a.title.localeCompare(b.title);
                          } catch (e) {
                            console.log(e);
                            return 0;
                          }
                        })
                        .map((product) => (
                          <tr key={product.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  {product.logo_url ? (
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={product.logo_url}
                                      alt={product.title}
                                    />
                                  ) : (
                                    <svg viewBox="0 0 448 512">
                                      <path
                                        fill="currentColor"
                                        d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {product.title}{" "}
                                    <small>
                                      ({product.image_count} images)
                                    </small>
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {product.id}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="ml-4 space-x-2">
                                {product.categories?.map((category) => (
                                  <span className="py-1 px-2.5 text-xs bg-blue-600 text-white rounded-full">
                                    {category}
                                  </span>
                                ))}
                              </div>
                            </td>
                            <td>
                              <div className="ml-4">
                                <div className="text-sm text-gray-800">
                                  Trademark:{" "}
                                  <a
                                    className="font-medium"
                                    href={`/trademark/${product.trademark_id}`}
                                  >
                                    {product.trademark_title}
                                  </a>
                                </div>
                                <div className="text-sm text-gray-800">
                                  Supplier:{" "}
                                  <a
                                    className="font-medium"
                                    href={`/supplier/${product.supplier_id}`}
                                  >
                                    {product.supplier_title}
                                  </a>
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mx-1 hover:cursor-pointer"
                                onClick={() => {
                                  if (
                                    confirm(
                                      "Are you sure you want to delete this?"
                                    )
                                  ) {
                                    removeDocument("products", product.id).then(
                                      () => {
                                        refetch();
                                      }
                                    );
                                  }
                                }}
                              >
                                Delete
                              </button>
                              <Link
                                to={`/product/${product.id}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
