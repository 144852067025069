import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfigFile from "../../tailwind.config";
import { callKurvan } from "../utils/KurvanAuth";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const uploadFile = (path, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = callKurvan(path, {
    method: "POST",
    headers: {
      Accept: "*/*",
    },
    body: formData,
  })
    .then((res) => {
      return JSON.parse(res);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });

  return result;
};

export const uploadImage = (path, image, storeId) => {
  const formData = new FormData();
  formData.append("file", image);
  formData.append("storeId", storeId);
  const result = callKurvan(path, {
    method: "POST",
    headers: {
      Accept: "*/*",
    },
    body: formData,
  })
    .then((res) => {
      return JSON.parse(res);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });

  return result;
};
