import React, { useState } from "react";

// Coords in Reykjavík
const defaultCoords = {
  lat: 64.78321062021682,
  lng: -18.378077682565973,
};

function getZoomLevelFromBounds(bounds, mapDim) {
  if (mapDim === undefined) {
    mapDim = {
      height: 300,
      width: 300,
    };
  }
  var WORLD_DIM = { height: 256, width: 256 };
  var ZOOM_MAX = 21;

  if (bounds.south === bounds.north && bounds.west === bounds.east) {
    return 21;
  }

  function latRad(lat) {
    var sin = Math.sin((lat * Math.PI) / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  var ne = {
    lat: bounds.north,
    lng: bounds.east,
  };
  var sw = {
    lat: bounds.south,
    lng: bounds.west,
  };

  var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

  var lngDiff = ne.lng - sw.lng;
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

function getZoomLevel(points, maxZoomLevel) {
  if (!Array.isArray(points)) {
    throw new Error("getZoomLevel expected an array");
  }
  if (points.length < 1) {
    return maxZoomLevel || 11;
  }

  const bounds = {
    north: undefined, //max
    south: undefined, //min
    east: undefined, //max
    west: undefined, //min
  };

  points.forEach((point) => {
    bounds.north = bounds.north > point.lat ? bounds.north : point.lat;
    bounds.south = bounds.south < point.lat ? bounds.south : point.lat;
    bounds.east = bounds.east > point.lng ? bounds.east : point.lng;
    bounds.west = bounds.west < point.lng ? bounds.west : point.lng;
  });

  const zoomLevel = getZoomLevelFromBounds(bounds);
  return zoomLevel > maxZoomLevel ? maxZoomLevel : zoomLevel;
}

function getMapCenter(points) {
  if (!Array.isArray(points) || points.length < 1) {
    return defaultCoords;
  }

  let latSum = 0;
  let lngSum = 0;

  points.forEach((point) => {
    latSum += point.lat;
    lngSum += point.lng;
  });

  return {
    lat: latSum / points.length,
    lng: lngSum / points.length,
  };
}

const MapMarker = ({ text, placeColor, ...props }) => {
  const K_SIZE = 50;
  const K_SIZE2 = 10;
  const borderColor = placeColor ?? "#f44336";
  const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: "absolute",
    width: K_SIZE2,
    height: K_SIZE2,
    left: -K_SIZE2 / 2,
    top: -K_SIZE2 / 2,

    borderRadius: K_SIZE2,
    backgroundColor: "white",
    textAlign: "center",
    color: "#3f51b5",
    fontSize: 12,
    fontWeight: "bold",
    padding: 0,
    cursor: "pointer",

    marginLeft: "0",
    borderRadius: "50%",
    border: `2px solid ${borderColor}`,
  };

  const greatPlaceStyleHover = {
    ...greatPlaceStyle,
    border: "2px solid #3f51b5",
    borderRadius: 4,
    marginLeft: "0",
    color: "#f44336",
    zIndex: 899,
    width: K_SIZE * 2,
    height: K_SIZE,
    left: (-K_SIZE * 2) / 2,
    top: -K_SIZE / 2,
  };

  const style = props.$hover ? greatPlaceStyleHover : greatPlaceStyle;
  const style2 = props.$hover ? { display: "inline" } : { display: "none" };
  const itemProps = props;
  for (let i in itemProps) {
    if (i.indexOf("$") === 0) delete itemProps[i];
  }
  return (
    <div style={style} {...itemProps} className="map-dot">
      <i style={style2}>{text}</i>
    </div>
  );
};

export { getZoomLevel, getMapCenter, MapMarker, defaultCoords };
