import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";

const PopupWrapper = styled.div`
  position: absolute;
  background: white;
  display: ${({ hidden }) => (hidden ? "none" : "block")};
`;
const InputStageWrapper = styled.div`
  border: 1px solid #64748b;
  height: 421px;

  input {
    width: 100%;
    border: none;
    border-top: 1px solid #64748b;
    outline: none;
    padding: 5px;

    &:active {
    }
    &:focus {
    }
  }
`;

const FadeToNone = styled.div`
  position: relative;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  user-select: none;
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 2%);
`;

const ProcessListCheck = styled.label`
  input {
    display: none;
  }
  label {
    display: block;
    border: 1px solid transparent;

    padding: 4px 5px;
    cursor: pointer;
    &.Selected {
      border: 1px solid rgb(4, 120, 87);
    }
    i.fa-square-check {
      color: rgb(4, 120, 87);
    }
  }
`;

function filterSearch(search, objProp) {
  return (item) => {
    if (item.name === "") return false;
    return search === "" || item[objProp].search(new RegExp(search, "i")) >= 0;
  };
}

export default function SearchItems({ items, searchBy, onSelect, ...props }) {
  const [search, setSearch] = useState("");
  const [selection, setSelection] = useState(-1);

  const filteredList = (items || [])
    .sort((a, b) => {
      if (searchBy in a) return a[searchBy].localeCompare(b[searchBy]);
      return 0;
    })
    .filter(filterSearch(search, searchBy));
  const keysToActions = {
    Escape: "deselect",
    ArrowUp: "moveUp",
    ArrowDown: "moveDown",
    //"S+ArrowUp": "expandSelectionUp",
    //"S+ArrowDown": "expandSelectionDown",
    Enter: "toggle",
    "C+Enter": "addAllFiltered",
    "C+Delete": "removeAllFiltered",
    "C+S+Delete": "removeAll",
  };
  const actions = {
    deselect: () => {
      if (search != "") {
        setSearch("");
      } else {
        setSelection(-1);
      }
    },
    moveUp: () => {
      const filteredLength = filteredList.length;
      if (filteredLength !== 0)
        setSelection((selection - 1 + filteredLength) % filteredLength);
      else setSelection(-1);
    },
    moveDown: () => {
      const filteredLength = filteredList.length;
      if (filteredLength !== 0) setSelection((selection + 1) % filteredLength);
      else setSelection(-1);
    },
    toggle: () => {
      if (selection > -1 && filteredList.length > 0) {
        const found = filteredList.at(selection);
        if (found) {
          onSelect("toggle", found);
        } else {
          console.log("not Found");
        }
      } else if (filteredList.length == 1) {
        const found = filteredList.at(0);
        onSelect("toggle", found);
      } else if (filteredList.length > 0) {
        setSelection(0);
      }
    },
    addAllFiltered: () => {
      onSelect("addAll", filteredList);
    },
    removeAllFiltered: () => {
      onSelect("removeAll", filteredList);
    },
    removeAll: () => {
      onSelect("clearAll");
    },
  };

  return (
    <InputStageWrapper>
      <FadeToNone className="overflow-y-scroll max-h-96 h-96">
        {filteredList.map((item, i) => (
          <ProcessListCheck
            key={item.id}
            ref={(ref) => {
              if (selection === i && ref) {
                ref.scrollIntoViewIfNeeded(false);
              }
            }}
          >
            <input
              id={"item" + i}
              type="checkbox"
              checked={props.selectedItems.includes(item.id)}
              onChange={(e) => {
                onSelect("toggle", item);
                //setSearch("")
                //setSelection(-1);
              }}
            />
            <label
              title={item[searchBy]}
              className={"truncate " + (selection === i ? "Selected" : "")}
              htmlFor={"item" + i}
            >
              <i
                className={`fa-solid ${
                  props.selectedItems.includes(item.id)
                    ? "fa-square-check"
                    : "fa-square"
                }`}
              />
              &nbsp;
              {item[searchBy]}
            </label>
          </ProcessListCheck>
        ))}
        <br />
        <br />
      </FadeToNone>
      <input
        tabIndex={1}
        type="text"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyUp={(e) => {
          var mod = "";
          if (e.ctrlKey) mod += "C+";
          if (e.shiftKey) mod += "S+";
          if (e.metaKey) mod += "A+";
          const keyCombo = `${mod}${e.key}`;
          const action = keysToActions[keyCombo];
          const actionFunc = actions[action];
          //console.log(keyCombo, action);
          if (actionFunc) {
            actionFunc();
          }
        }}
      />
    </InputStageWrapper>
  );
}
