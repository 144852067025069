import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import { useGetDocumentById } from "../utils/Kurvanstore";
import { useParams } from "react-router-dom";
import {
  getZoomLevel,
  getMapCenter,
  defaultCoords,
  MapMarker,
} from "../utils/GoogleMaps";

function Date() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [edited, setEdited] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const { id } = useParams();
  const { data, loading, setData } = useGetDocumentById("dates", id);

  useEffect(() => {
    if (data !== null && data.id !== id) {
      setEdited(true);
      setIsNew(true);
    } else {
      setIsNew(false);
      setEdited(false);
    }
    setFormData(data || { id });
    setSubmitted(false);
  }, [data]);

  const onSubmit = (e) => {
    e.preventDefault();
    setData(formData);
    setSubmitted(true);
  };

  const updateformData = (fieldName) => (event) => {
    updateFormDataState(fieldName, event.target.value);
  };

  const innerMapFieldUpdate = (outerKey, innerKey, field, value) => {
    innerMapUpdate(outerKey, innerKey, {
      ...formData[outerKey][innerKey],
      [field]: value,
    });
  };
  const innerMapUpdate = (outerKey, innerKey, value) => {
    updateFormDataState(outerKey, {
      ...formData[outerKey],
      [innerKey]: value,
    });
  };

  const updateFormDataState = (fieldName, value) => {
    setFormData({
      type: "special",
      length: 1,
      ...formData,
      [fieldName]: value,
    });
    setEdited(true);
    setSubmitted(false);
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Date &nbsp;
                      <span className="text-sm text-red-600">
                        {edited ? "(Unsaved Changes)" : ""}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This the system information behind each Date to be careful
                      what you edit
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" onSubmit={onSubmit}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.name}
                              onChange={updateformData("name")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="about"
                              name="about"
                              rows={3}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Lorem ipsum ..."
                              defaultValue={""}
                              value={formData.description}
                              onChange={updateformData("description")}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date Type
                          </label>
                          <div className="mt-1">
                            <select
                              type="text"
                              name="type"
                              id="type"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.type}
                              onChange={updateformData("type")}
                            >
                              <option value="special">Special day</option>
                              <option value="holiday">Holiday</option>
                              <option value="event">event</option>
                              <option value="birthday">birthday</option>
                            </select>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date of date
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="date"
                              id="date"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.date}
                              onChange={(e) =>
                                updateFormDataState("date", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="length"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Length of date
                          </label>
                          <div className="mt-1">
                            <input
                              type="number"
                              step="1"
                              name="length"
                              id="length"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              defaultValue={1}
                              value={formData.length}
                              onChange={(e) =>
                                updateFormDataState(
                                  "length",
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="logo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Logo image path
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="logo"
                              id="logo"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              placeholder="https//.../my-image.png"
                              value={formData.logo}
                              onChange={updateformData("logo")}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Logo (Not yet implemented)
                          </label>
                          <div className="mt-1 flex items-center">
                            <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                              {formData.logo ? (
                                <img
                                  className="h-full w-full text-gray-300"
                                  src={formData.logo}
                                />
                              ) : (
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              )}
                            </span>
                            <button
                              type="button"
                              className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Link
                          to="/Dates"
                          className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {submitted ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}

export default Date;
