import React from "react";
import { Link } from "react-router-dom";
import LineChart from "../../charts/LineChart01";
import DefaultIcon from "../../images/icon-01.svg";
import EditMenu from "../EditMenu";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";

function Card01({ title, subTitle, number, change, icon }) {
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="p-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          {icon ? (
            <img src={icon} width="32" height="32" alt={title} />
          ) : (
            <img src={DefaultIcon} width="32" height="32" alt={title} />
          )}
        </header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">{title}</h2>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
          {subTitle}
        </div>
        <div className="flex items-start">
          <div className="text-3xl font-bold text-gray-800 mr-2">{number}</div>
          {change && (
            <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
              {change}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Card01;
