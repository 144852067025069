import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { useParams } from "react-router-dom";

import EditAreaDialog from "./Store/EditAreaDialog";
import NewAreaDialog from "./Store/NewAreaDialog";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";

import {
  useGetDocumentById,
  useSetDocumentById,
  useGetAllDocuments,
  removeDocument,
} from "../utils/Kurvanstore";
import {
  getZoomLevel,
  getMapCenter,
  defaultCoords,
  MapMarker,
} from "../utils/GoogleMaps";

function Store() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [edited, setEdited] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  let navigate = useNavigate();
  const { id } = useParams();
  const { data, loading, setData } = useGetDocumentById("stores", id);
  const { data: areas, refetch } = useGetAllDocuments(`areas_by_store/${id}`);
  const { data: franchises} = useGetAllDocuments(`franchises`);
  const franchise = franchises?.find((f) => f.id === formData?.franchiseId);

  useEffect(() => {
    if(id !== "new" && formData?.id && id !== formData?.id) {
      navigate(`/store/${formData.id}`);
    }
    setFormData(data || {
      id,
      name: "",
      franchiseId: "",
    });
    setEdited(false);
    setSubmitted(false);
  }, [data]);

  const onSubmit = (e) => {
    e.preventDefault();
    if(formData?.franchiseId?.length < 10){
      alert("Please select a franchise");
      return;
    }
    if(formData?.name?.length < 3){
      alert("Please set store name");
      return;
    }
    setData(formData);
    setSubmitted(true);
  };

  const updateFormData = (fieldName) => (event) => {
    updateFormDataState(fieldName, event.target.value);
  };
  const updateFormDataState = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
    setEdited(true);
    setSubmitted(false);
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div
                style={{
                  width: "100%",
                  height: "300px",
                  marginBottom: 10,
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCd0bKIf2AqsdP4jSIyCH3231VGYHjqd6o",
                  }}
                  {...{
                    center: getMapCenter([formData.gps || defaultCoords]),
                    zoom: formData.gps ? getZoomLevel([formData.gps], 15) : 6,
                    onClick: ({ lat, lng }) =>
                      updateFormDataState("gps", { lat, lng }),
                  }}
                >
                  <MapMarker
                    key={formData.id}
                    {...(formData.gps || defaultCoords)}
                    text={formData.name}
                  ></MapMarker>
                </GoogleMapReact>
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Store &nbsp;
                      <span className="text-sm text-red-600">
                        {edited ? "(Unsaved Changes)" : ""}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This the system information behind each Store to be
                      careful what you edit
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" onSubmit={onSubmit}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.name}
                              onChange={updateFormData("name")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="about"
                              name="about"
                              rows={3}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Lorem ipsum ..."
                              defaultValue={""}
                              value={formData.description}
                              onChange={updateFormData("description")}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="franchise"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Franchise
                          </label>
                          <div className="mt-1">
                            <select
                              name="franchise"
                              id="franchise"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              value={formData.franchiseId}
                              onChange={updateFormData("franchiseId")}
                            >
                              <option value="">Select a franchise</option>
                              {franchises?.map((f) => (
                                <option key={f.id} value={f.id}>
                                  {f.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="logo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Logo image path
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="logo"
                              id="logo"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              placeholder="https//.../my-image.png"
                              value={formData.logo}
                              onChange={updateFormData("logo")}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Logo (Not yet implemented)
                          </label>
                          <div className="mt-1 flex items-center">
                            <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                              {formData.logo ? (
                                <img
                                  className="h-full w-full text-gray-300"
                                  alt={formData.name}
                                  src={formData.logo}
                                />
                              ) : (
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              )}
                            </span>
                            <button
                              type="button"
                              className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="gps.lat"
                            className="block text-sm font-medium text-gray-700"
                          >
                            GPS
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="gps.lat"
                              id="gps.lat"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                              placeholder="64.12139842079655,-21.87537269505434"
                              value={
                                formData.gps
                                  ? formData.gps.lat + "," + formData.gps.lng
                                  : ""
                              }
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  updateFormDataState("gps", undefined);
                                  return;
                                }
                                const b = e.target.value.split(",");
                                const [lat, lng] = b;
                                updateFormDataState("gps", {
                                  lat: Number(lat),
                                  lng: Number(lng),
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="closedDown"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Hætt starfsemi
                          </label>
                          <div className="mt-1">
                            <input
                              type="checkbox"
                              name="closedDown"
                              id="closedDown"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block sm:text-sm border-gray-300"
                              value="1"
                              checked={formData.closedDown}
                              onChange={(e) => {
                                console.log(e.target.value, e.target.checked);
                                updateFormDataState("closedDown", !formData.closedDown);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <Link
                          to="/Stores"
                          className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Back
                        </Link>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {submitted ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                  {formData.id && (
                    <div className="bg-white rounded-sm p-2 m-2">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              <span className="text-md">Name</span>
                            </th>
                            <th scope="col" className="px-6 py-3">
                              <span className="text-md">Búð</span>
                            </th>
                            <th scope="col" className="px-6 py-3">
                              <span className="text-md">
                                <NewAreaDialog
                                  store={data}
                                  onAdd={() => refetch()}
                                />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {(areas || []).map((p) => {
                            return (
                              <tr key={p.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="ml-4 text-sm font-medium text-gray-900">
                                    {p.title}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="ml-4 text-sm font-medium text-gray-900">
                                    {new Date(p.dateCreated).toLocaleDateString(
                                      "en-GB"
                                    )}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="ml-4 text-sm font-medium text-gray-900">
                                    <EditAreaDialog
                                      store={data}
                                      id={p.id}
                                      onAdd={() => refetch()}
                                    />
                                    <button
                                      className="inline-flex justify-center py-1 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      onClick={async () => {
                                        await removeDocument(
                                          "store_areas",
                                          p.id
                                        );
                                        refetch();
                                      }}
                                    >
                                      remove
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}

export default Store;
