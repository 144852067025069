import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { v1 } from "uuid";
import moment from "moment";
import * as XLSX from "xlsx-js-style";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Banner from "../partials/Banner";
import { useGetAllDocuments, removeDocument } from "../utils/Kurvanstore";

export default function PricePoints() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filterMissing, setFilterMissing] = useState(true);

  const { data: categories } = useGetAllDocuments("categories");
  const { data: sub_categories } = useGetAllDocuments("sub_categories");
  const { data, loading, refetch } = useGetAllDocuments("price_survey_points");

  const ExportToExcel = (jsonData) => {
    var data = jsonData.map((item) => {
      const subs = sub_categories.filter((sub) => {
        return item.subCategoryIds?.includes(sub.id) || false;
      });

      const cats = subs.map((sub) => {
        return categories.find((cat) => {
          return cat.id === sub.categoryId;
        });
      });

      return {
        ID: item.id,
        Dags: moment(item.date).format("D/M/Y"),
        Búð: item.store.name,
        flokkur: cats
          .map((cat) => cat.title)
          .unique()
          .join(", "),
        undirflokkur: subs.map((sub) => sub.title).join(", "),
        Vara: item.product.title,
        Verð: item.price,
        created: item.dateCreated,
        modified: item.dateModified,
      };
    });
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, `Verðlisti_${new Date().toJSON().slice(0, 10)}.xlsx`);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Dags.
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Búð
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Vara
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Verð
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>

                          <button
                            className="text-indigo-600 hover:text-indigo-900 mx-1 hover:cursor-pointer"
                            onClick={() => {
                              ExportToExcel(data);
                            }}
                          >
                            Export
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {(data || [])
                        .filter((point) => {
                          if (filterMissing && point.missing) {
                            return false;
                          }
                          return true;
                        })
                        .sort((a, b) => a.date.localeCompare(b.date))
                        .map((point) => (
                          <tr key={point.id}>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="">
                                <div className="text-sm font-medium text-gray-900">
                                  {moment(point.date).format("D/M/Y")}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {/*point.id*/}
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="">
                                <div className="text-sm font-medium text-gray-900">
                                  {point.store.name}
                                </div>
                                <div className="text-sm text-gray-500"></div>
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="">
                                <div className="text-sm font-medium text-gray-900">
                                  {point.product.title}
                                </div>
                                <div className="text-sm text-gray-500"></div>
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="">
                                <div className="text-right text-sm font-medium text-gray-900">
                                  {point.price} kr
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mx-1 hover:cursor-pointer"
                                onClick={() => {
                                  if (
                                    confirm(
                                      "Are you sure you want to delete this?"
                                    )
                                  ) {
                                    removeDocument(
                                      "price_survey_points",
                                      point.id
                                    ).then(() => {
                                      refetch();
                                    });
                                  }
                                }}
                              >
                                Delete
                              </button>
                              {/*

                            <Link to={`/measurement/${measurement.id}`} className="text-indigo-600 hover:text-indigo-900">Edit</Link>
                            */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}
