import React, { useState } from "react";
import GoogleMapReact from "google-map-react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import Datepicker from "../partials/actions/Datepicker";
import Card01 from "../partials/dashboard/Card01";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard08 from "../partials/dashboard/DashboardCard08";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DashboardCard12 from "../partials/dashboard/DashboardCard12";
import DashboardCard13 from "../partials/dashboard/DashboardCard13";
import Banner from "../partials/Banner";
import { useGetAllDocuments } from "../utils/Kurvanstore";
import { getZoomLevel, getMapCenter, MapMarker } from "../utils/GoogleMaps";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data: customers } = useGetAllDocuments("accounts");
  const { data: franchises } = useGetAllDocuments("franchises");
  const { data: stores } = useGetAllDocuments("stores");

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              <Card01 title={"Number of accounts"} number={customers?.length} />
              <Card01
                title={"Number of franchises"}
                number={franchises?.length}
              />
              <Card01 title={"Number of stores"} number={stores?.length} />
            </div>

            <div className="grid grid-cols-1 gap-6">
              <div
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "600px",
                  marginBottom: "30px",
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCd0bKIf2AqsdP4jSIyCH3231VGYHjqd6o",
                  }}
                  {...{
                    center: getMapCenter(
                      stores
                        ?.filter((i) => i && i.gps && i.gps.lat && i.gps.lng)
                        .map((i) => i.gps)
                    ),
                    zoom: getZoomLevel(
                      stores
                        ?.filter((i) => i && i.gps && i.gps.lat && i.gps.lng)
                        .map((i) => i.gps) || [],
                      11
                    ),
                    //onChildClick: (e)=> console.log(e)
                  }}
                >
                  {stores
                    ?.filter((i) => i && i.gps && i.gps.lat && i.gps.lng)
                    .map((store) => (
                      <MapMarker
                        key={store.id}
                        {...store.gps}
                        text={store.name}
                      ></MapMarker>
                    ))}
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </main>

        <Banner />
      </div>
    </div>
  );
}

export default Dashboard;
