const isDev = process.env.NODE_ENV == "development";
const isTesting = process.env.NODE_ENV == "testing";

const defaults = {
  backend_url: "https://api.kurvan.io",
  functions_url: "https://europe-west1-kurvan-io.cloudfunctions.net",
};

var overrides = {};
if (isDev) {
  overrides = {
    ...overrides,
    backend_url: "http://localhost:8080",
    functions_url: "http://localhost:5001/kurvan-io/europe-west1",

    //ingimar nginx
    //backend_url: "https://app.kurvan.local",
    //functions_url: "http://functions.kurvan.local/kurvan-io/europe-west1",
  };
}

export default {
  ...defaults,
  ...overrides,
};
