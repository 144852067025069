window.myServiceWorker = undefined;
window.isServiceWorkerReady = false;
if ("serviceWorker" in navigator) {
  console.log("Main: found serviceWorker!");
  navigator.serviceWorker
    .register(new URL("../static/sw.js", import.meta.url), { type: "module" })
    .then(navigator.serviceWorker.ready)
    .then(function (e) {
      console.log("Service Worker ready", e);
      window.isServiceWorkerReady = true;
      window.myServiceWorker = e;
    });
} else {
  console.log("Main: did not find serviceWorker");
}
import "./index.js";
